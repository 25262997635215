import { Ampli, ampli } from "@/ampli";
import * as amplitude from "@amplitude/analytics-browser";
import { createContext } from "react";

type TrackingState = {
  ampli: Ampli;
};

export const setupAmpli = (apiKey: string): Ampli => {
  const enabled: boolean = Boolean(
    apiKey && apiKey !== "tracking-key-goes-here",
  );

  // console.log(`Ampli enabled: ${enabled}`);

  if (enabled) {
    amplitude.init(apiKey, "", {
      defaultTracking: {
        pageViews: true,
        sessions: true,
      },
    });
  }
  ampli.load({ disabled: !enabled, client: { instance: amplitude } });
  return ampli;
};

export const TrackingContext = createContext<TrackingState>({
  ampli,
});

type Props = {
  ampli: Ampli;
};

const TrackingStateProvider = ({ ampli }: Props) => {
  const trackingState: TrackingState = {
    ampli,
  };
  return <TrackingContext.Provider value={trackingState} />;
};

export default TrackingStateProvider;
