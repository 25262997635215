import { Box, Typography } from "@mui/material";
import EmptyInbox from "@/components/Icons/EmptyInbox";
import { useContext } from "react";
import { UxContext } from "@/models/UxStateProvider";
import TheButton from "@/components/TheButton";

export default function ChannelEmptyState({
  channelType,
  hasSearch,
  changeChannelType,
  limitedMember,
}: {
  channelType: string;
  hasSearch: boolean;
  changeChannelType: (channelType: string) => void;
  limitedMember: boolean;
}) {
  const { setChannelModalOpen } = useContext(UxContext);

  if (hasSearch) {
    return (
      <Box className="unread-state">
        <Typography fontWeight="bold" variant="h5">
          No channels found
        </Typography>
        <Box>
          {limitedMember ? (
            <Box>Try searching for something else</Box>
          ) : (
            <Box>
              Try searching for something else or
              <TheButton
                className="my-channels-button"
                onClick={() => {
                  setChannelModalOpen(true);
                }}
              >
                Create a new channel.
              </TheButton>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  if (channelType === "my-channels") {
    return (
      <Box className="unread-state">
        <Typography fontWeight="bold" variant="h5">
          No channels found
        </Typography>
        <Box>
          You haven't joined any channels yet. <br />
          {!limitedMember && (
            <>
              Go to{" "}
              <TheButton
                className="my-channels-button"
                onClick={() => {
                  changeChannelType("all-channels");
                }}
              >
                All Channels
              </TheButton>{" "}
              to see more conversations.
            </>
          )}
        </Box>
      </Box>
    );
  }

  if (channelType === "my-unreads") {
    return (
      <Box className="unread-state">
        <Box className="unread-state-icon">
          <EmptyInbox />
        </Box>
        <Typography fontWeight="bold" variant="h5">
          You are all caught up.
        </Typography>
        <Box>New unread messages will appear here.</Box>
        {!limitedMember && (
          <Box>
            Go to{" "}
            <TheButton
              className="my-channels-button"
              onClick={() => {
                changeChannelType("all-channels");
              }}
            >
              All Channels
            </TheButton>{" "}
            to see more conversations.
          </Box>
        )}
      </Box>
    );
  }

  if (channelType === "all-channels") {
    return (
      <Box className="unread-state">
        <Typography fontWeight="bold" variant="h5">
          No channels found
        </Typography>
        <Box>You haven't joined any channels yet.</Box>
        {!limitedMember && (
          <Box>
            <TheButton
              className="my-channels-button"
              onClick={() => {
                setChannelModalOpen(true);
              }}
            >
              Create a new channel.
            </TheButton>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box className="unread-state">
      <Typography fontWeight="bold" variant="h5">
        No channels found
      </Typography>
      <Box>
        <TheButton
          className="my-channels-button"
          onClick={() => {
            setChannelModalOpen(true);
          }}
        >
          Create a new channel.
        </TheButton>
      </Box>
    </Box>
  );
}
