import App from "@/App";
import { setupAmpli } from "@/models/TrackingStateProvider";
import { createBrowserRouter } from "react-router-dom";
import Client from "web-client/client";

import RouteErrorFallback from "@/components/RouteErrorFallback";
import { PgTest } from "./pgTest";
import Root from "./root";
import RedirectNotification from "@/components/RedirectNotification";
import DirectAuth from "./directAuth";
import Onboarding from "./onboarding";
import Workspace from "./workspace";
import DebugItemId from "./workspace/debugItem";
import FeedId from "./workspace/feedId";
import LoadingWorkspace from "./workspace/LoadingWorkspace";
import MaintenanceMode from "./workspace/MaintenanceMode";
import TemplateManagement from "./workspace/templateManagement";
import Workflows from "./workspace/workflows";
import WorkspaceUserManagement from "./workspace/workspaceUserManagement";
import WorkspaceId from "./workspace/workspaceId";
import AuthSuccess from '@/routes/authSuccess';

export type ConfigData = {
  baseDomain: string;
  tusUrl: string;
  graphqlUrl: string;
  graphqlApiKey: string;
  sessionRecordingLength: number;
  gaKey: string;
  env: string;
  trackingKey?: string;
  debugMode?: boolean;
};

export type FlagData = {
  debugMode?: boolean;
  recordingDebug?: boolean;
  listenOnly?: boolean;
  appPhone?: boolean;
  workspaces?: boolean;
  voiceAssistant?: boolean;
  sqlEditor?: boolean;
};

const config: ConfigData = {
  baseDomain: import.meta.env.VITE_BASE_DOMAIN,
  tusUrl: import.meta.env.VITE_TUS_URL,
  graphqlUrl: import.meta.env.VITE_GRAPHQL_URL,
  graphqlApiKey: import.meta.env.VITE_GRAPHQL_API_KEY,
  sessionRecordingLength: Number(import.meta.env.VITE_SESSION_RECORDING_LENGTH),
  gaKey: import.meta.env.VITE_GA_KEY,
  env: import.meta.env.MODE,
  trackingKey: import.meta.env.VITE_TRACKING_KEY ?? "",
  debugMode: import.meta.env.VITE_DEBUG_MODE === "true",
};

declare const window: Window & { dataLayer: Record<string, unknown>[] };
const debugFromUrl = new URLSearchParams(window.location.search).get("debug");
if (debugFromUrl) {
  config.debugMode = true;
  console.log("Force setting debug mode");
}

const flags: FlagData = {
  debugMode: config.debugMode,
  recordingDebug: false,
  listenOnly: true,
  appPhone: true,
  workspaces: config.debugMode,
  voiceAssistant: false,
  sqlEditor: false,
};

const ampli = setupAmpli(config.trackingKey);

const client = new Client({
  baseDomain: config.baseDomain,
  logout: (returnTo?: string) => {
    ampli.client.setUserId(null);
    if (returnTo) {
      window.location.href = `${config.baseDomain}/auth/logout?returnTo=${returnTo}`;
    } else {
      window.location.href = `${config.baseDomain}/auth/logout`;
    }
  },
  login: () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: {
          name: "authScreen",
          value: "Auth0 App: SMS",
        },
      });
    }
    ampli.authScreen({ "Auth0 App": "SMS" });
    const origin = window.location.origin;
    const appPath = encodeURIComponent(
      `${window.location.pathname}${window.location.search}`,
    );
    const returnTo = `${origin}/authSuccess?returnTo=${appPath}`;
    window.location.href = `${config.baseDomain}/auth/login?returnTo=${returnTo}`;
  },
});

export const router = createBrowserRouter([
  {
    element: (
      <App config={config} flags={flags} client={client} ampli={ampli} />
    ),
    errorElement: <RouteErrorFallback />,
    children: [
      {
        path: "/",
        element: <Root />,
      },
      {
        path: "/test",
        element: <PgTest />,
      },
      {
        path: "/workspaces",
        element: <Workspace />,
        children: [
          {
            path: "/workspaces/:workspaceId",
            element: <WorkspaceId />,
          },
          {
            path: "/workspaces/:workspaceId/loading-workspace",
            element: <LoadingWorkspace />,
          },
          {
            path: "/workspaces/:workspaceId/feeds/:feedId",
            element: <FeedId />,
          },
          {
            path: "/workspaces/:workspaceId/feeds/:feedId/items/:itemId/debug",
            element: <DebugItemId />,
          },
          {
            path: "/workspaces/:workspaceId/user-management",
            element: <WorkspaceUserManagement />,
          },
          {
            path: "/workspaces/:workspaceId/workflows",
            element: <Workflows />,
          },
          {
            path: "/workspaces/:workspaceId/template-management",
            element: <TemplateManagement />,
          },
        ],
      },
      {
        path: "/onboarding",
        element: <Onboarding />,
      },
      {
        path: "/authSuccess",
        element: <AuthSuccess />,
      },
      {
        path: "/notification-redirect",
        element: <RedirectNotification />,
      },
      {
        path: "/directAuth",
        element: <DirectAuth />,
      },
      {
        path: "/maintenance-mode",
        element: <MaintenanceMode />,
      },
    ],
  },
]);
