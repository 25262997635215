import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { useContext } from "react";
import AvatarWithStatusContent from "./AvatarWithStatusContent";
import { WorkspaceMember } from "@/data/workspaceMemberships";

type SizeType = "xs" | "sm" | "md" | "lg";

interface AvatarProps {
  size?: SizeType; // Avatar size
  accountId?: string; // Account ID of the user
  member?: WorkspaceMember; // Member info if there is no account id
  disableTooltip?: boolean; // Disable tooltip
}

export default function AvatarWithStatus({
  size,
  accountId,
  member,
  disableTooltip,
}: AvatarProps) {
  const { accountMap } = useContext(WorkspaceContext);
  const accountRecordWithStatus = accountMap.get(
    accountId || member?.accountId,
  );

  return (
    <AvatarWithStatusContent
      size={size}
      accountRecord={accountRecordWithStatus}
      member={member}
      disableTooltip={disableTooltip}
    />
  );
}
