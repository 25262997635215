import { AppContext } from "@/models/AppStateProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { buildFileMetaData, fetchUploadConfig } from "@/utils";
import { fileUploadConfig } from "@/utils/config";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import cuid from "cuid";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ActionContext } from "./ActionsProvider";
import { DataContext } from "./DataProvider";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";
import { MyAccountContext } from "./StateProviders/myAccountProvider";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";

type UppyState = {
  acceptedTypes?: any[];
  addedFile?: any;
  addMetaData: (metadata: any) => void;
  removeMetaData: (key: string) => void;
  hasFiles?: boolean;
  isAudioFileUpload?: boolean;
  isScheduledUpload?: boolean;
  note?: string;
  removeFiles: () => void;
  removePlugin: (pluginName: string) => void;
  setIsScheduledUpload: (scheduled: boolean) => void;
  uppyClient?: Uppy;
  uploading?: boolean;
  uploadStart?: Date | null;
  uploadUrl?: string;
};

interface Restrictions {
  maxNumberOfFiles?: number;
  minNumberOfFiles?: number;
  allowedFileTypes?: string[];
}

let uppyInstance: Uppy;

export const UppyContext = createContext<UppyState>({
  addMetaData: () => {},
  removeMetaData: () => {},
  removeFiles: () => {},
  removePlugin: () => {},
  setIsScheduledUpload: () => {},
});

const UppyContextProvider = ({ children }) => {
  const [uploadUrl, setUploadUrl] = useState<string>("");
  const [uploadStart, setUploadStart] = useState<Date | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [hasFiles, setHasFiles] = useState<boolean>(false);
  const [tempContentId, setTempContentId] = useState<string | null>(null);
  const [addedFile, setAddedFile] = useState<any>();
  const isAudioFileUpload = useMemo(
    () => addedFile?.meta?.type === "audio",
    [addedFile],
  );
  const [isScheduledUpload, setIsScheduledUpload] = useState<boolean>(false);
  const { config, client } = useContext(AppContext);
  const { preferredLanguage } = useContext(DataContext);
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const { myAccountId } = useContext(MyAccountContext);
  const { publishToWorkspaceFeed } = useContext(ActionContext);
  const { stage, tusUrl } = fetchUploadConfig(config);
  const { ampli } = useContext(TrackingContext);
  const { currentFeedId: feedId } = useContext(CurrentFeedContext);
  const workspaceId = currentWorkspaceId;

  const acceptedTypes = fileUploadConfig?.acceptedTypes?.length
    ? fileUploadConfig?.acceptedTypes.map((type) => `.${type}`)
    : [];
  const note = fileUploadConfig?.acceptedTypes?.length
    ? `Allowed Types: ${acceptedTypes.join(", ")}`
    : "";

  const defaultRestrictions = { minNumberOfFiles: 1 };
  const restrictions = defaultRestrictions as Restrictions;
  restrictions.maxNumberOfFiles = fileUploadConfig?.multiple === true ? 10 : 1;
  if (acceptedTypes?.length > 0) {
    restrictions.allowedFileTypes = acceptedTypes;
  }

  const handleFileAdded = async (file) => {
    console.log(file);
    setHasFiles(true);
    const setTime = new Date();

    const newContentId = cuid();
    setTempContentId(() => newContentId);
    const itemId = cuid();
    client.createContentEvent({
      contentId: newContentId,
      workspaceId: currentWorkspaceId,
      step: "client_processing",
      status: "started",
      context: "file",
      feedId,
    });

    const fileMeta = await buildFileMetaData({
      file,
      stage,
      feedId,
      itemId,
      myAccount: myAccountId,
      uniqueUploadId: newContentId,
    });

    setUploadStart(setTime);

    file.meta = {
      ...file.meta,
      ...fileMeta,
      contentId: newContentId,
      startTime: setTime,
      preferredLanguage,
      inputType: "File",
      // default all uploads to silent
      isSilent: true,
    };
    setAddedFile(file);

    client.createContentEvent({
      workspaceId: currentWorkspaceId,
      contentId: newContentId,
      step: "client_uploading",
      status: "started",
      context: "file",
      feedId,
    });

    ampli.fileUploadPhotoGallery();
    return file;
  };

  const handleUploadSuccess = async (file, response) => {
    const contentId = file?.meta?.contentId as string;
    const itemId = file?.meta?.feedItemId as string;
    setTempContentId(() => null);
    if (response?.uploadURL && contentId) {
      try {
        // if we have a feedId then skip publishing for now
        if (file?.feedId) {
          publishToWorkspaceFeed({
            workspaceId,
            feedId,
            contentId,
            itemId,
            isSilent: true,
          });
        }
        uppyInstance.removeFile(file.id);
      } catch (e) {
        client.createContentEvent({
          workspaceId: currentWorkspaceId,
          contentId,
          step: "client_uploading",
          status: "failed",
          context: "file",
          feedId,
          error: e,
        });
        console.log("Error during file publish", e);
      } finally {
        setHasFiles(false);
        setAddedFile(null);
        setUploading(false);
        setUploadUrl("");
        setUploadStart(null);
        setIsScheduledUpload(false);
        const duration =
          (new Date().getTime() -
            new Date(file?.meta?.startTime?.toString()).getTime()) /
          1000;

        client.createContentEvent({
          contentId,
          workspaceId: currentWorkspaceId,
          step: "client_uploading",
          status: "finished",
          context: `file, duration: ${duration}`,
          feedId,
        });
      }
    }
  };

  const addMetaData = (metadata: any) => {
    if (metadata) {
      const fileId = addedFile?.id;
      uppyInstance.setFileMeta(addedFile?.id, { ...metadata });
      const fileWNewMeta = uppyInstance.getFile(fileId);
      // update the added file
      setAddedFile(fileWNewMeta);
    }
  };

  const removeMetaData = (key: string) => {
    const updatedFiles = { ...uppyInstance.getState().files };
    const override = addedFile?.meta;
    delete override[key];
    const newMeta = { ...override };
    const fileId = addedFile?.id;
    updatedFiles[fileId] = { ...updatedFiles[fileId], meta: newMeta };
    uppyInstance.setState({ files: updatedFiles });
    const fileWNewMeta = uppyInstance.getFile(fileId);
    // update the added file
    setAddedFile(fileWNewMeta);
  };

  const removeFiles = () => {
    if (uppyInstance) {
      const files = uppyInstance?.getFiles();
      if (files?.length > 0) {
        files.forEach((file) => uppyInstance.removeFile(file.id));
        setHasFiles(false);
        setAddedFile(null);
      }
    }
  };

  const removePlugin = (pluginName: string) => {
    const plugin = uppyInstance?.getPlugin(pluginName);
    if (plugin) {
      uppyInstance?.removePlugin(plugin);
    }
  };

  useEffect(() => {
    uppyInstance = new Uppy({
      autoProceed: false,
      debug: true,
      restrictions,
      onBeforeUpload: (file) => {
        ampli.fileUploadSubmit();
        return file;
      },
    }).use(Tus, {
      endpoint: tusUrl,
      removeFingerprintOnSuccess: true,
      retryDelays: [0, 1000, 3000, 5000],
      limit: 1,
    });

    uppyInstance?.on("file-added", handleFileAdded);

    uppyInstance?.on("file-removed", (file) => {
      console.log("Removed file", file);
    });

    uppyInstance?.on("upload-success", handleUploadSuccess);

    uppyInstance?.on("upload-progress", () => {
      setUploading(true);
    });

    uppyInstance?.on("error", (error) => {
      console.error("ERROR", error);
      client
        .createContentEvent({
          workspaceId: currentWorkspaceId,
          contentId: tempContentId,
          step: "client_uploading",
          status: "failed",
          context: "file",
          feedId,
          error: error,
        })
        ?.then(() => {
          setTempContentId(() => null);
          ampli.fileUploadError();
        });
    });
  }, [preferredLanguage, myAccountId, workspaceId, feedId]);

  const uppyState: UppyState = {
    acceptedTypes,
    addedFile,
    addMetaData,
    removeMetaData,
    hasFiles,
    isAudioFileUpload,
    isScheduledUpload,
    note,
    removeFiles,
    removePlugin,
    setIsScheduledUpload,
    uppyClient: uppyInstance,
    uploading,
    uploadStart,
    uploadUrl,
  };

  return (
    <UppyContext.Provider value={uppyState}>{children}</UppyContext.Provider>
  );
};
export default UppyContextProvider;
