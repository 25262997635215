import { db } from "@/db/db";
import { sql } from 'drizzle-orm'
import ModalForm from "@/elements/ModalForm";
import { Button } from "@mui/material";
import { useState } from "react";
export default function SqlEditor({
  open,
  close,
}: { open: boolean; close: () => void }) {

  const [results, setResults] = useState<unknown[]>([]);
  const handleClose = () => {
    setResults(() => []);
    console.clear();
    close();
  };

  const submit = async (e) => {
    const query = e.target[0].value
    e.preventDefault();

    try{
      const queryResponse = await db.execute(query)
      if(queryResponse?.rows){
        setResults(() => queryResponse.rows)
      } else {
        console.log({ e });
        setResults(() => e);
      }
    } catch (e){
      console.log({ e });
      setResults(() => e);
    }
  };

  return (
    <ModalForm
      maxWidth={1200}
      maxHeight={1200}
      sx={{
        height: "calc(100vh - 300px)",
      }}
      open={open}
      onClose={() => handleClose()}
    >
      <form style={{ width: "100%" }} onSubmit={(e) => submit(e)}>
        <textarea
          style={{
            outline: "none",
            padding: "10px",
            width: "100%",
            border: "none",
            color: "#fff",
            background: "#111",
            resize: "none",
            height: "calc(100vh - 700px)",
          }}
        />
        <Button type="submit">Submit</Button>
        <pre
          style={{
            background: "#222",
            maxHeight: "250px",
            overflowY: "auto",
            fontSize: "12px",
          }}
        >
          {JSON.stringify(results, null, 2)}
        </pre>
      </form>
    </ModalForm>
  );
}
