import { Link as LinkType } from "@/db/types";
import { Link, Typography } from "@mui/material";

export default function LinkFragment(link: LinkType) {
  const truncateURL = (url: string) => {
    if (!url || url?.length === 0) {
      return "...";
    }
    const shortEnd = url.slice(url.length - 15);
    const shortStart = url
      .replace("www.", "")
      .replace("http://", "")
      .replace("https://", "")
      .substr(0, 30);
    return `${shortStart}...${shortEnd}`;
  };

  const image = link?.image && (
    <img
      src={link.image}
      alt={link?.title ?? link.description}
      style={{
        borderRadius: "0.5rem",
        maxWidth: "100%",
        width: "100%",
        height: "auto",
        maxHeight: "200px",
        objectFit: "cover",
        objectPosition: "top center",
      }}
    />
  );

  const title = link?.title && (
    <Typography sx={{ wordBreak: "break-words" }}>{link.title}</Typography>
  );

  const description = !title && link?.description && (
    <Typography sx={{ wordBreak: "break-words" }}>
      {link.description}
    </Typography>
  );

  let shortUrl = (
    <Typography
      sx={{
        color: "#fff",
        opacity: "0.7",
        textDecoration: "underline",
        fontSize: "12px",
        "&:hover": { opacity: "1.0" },
        wordBreak: "break-all",
      }}
    >
      {truncateURL(link.url)}
    </Typography>
  );

  if (!image && !title && !description) {
    shortUrl = (
      <Typography sx={{ wordBreak: "break-all" }}>{link.url}</Typography>
    );
  }

  return (
    <Link sx={{ textDecoration: "underline" }} href={link.url} target="_blank">
      {image}
      {title}
      {description}
      {shortUrl}
    </Link>
  );
}
