function brightLog(message: string, color: string = "#00ff00") {
  console.log(
    `%c${message}`,
    `color: ${color}; font-weight: bold; font-size: 1.2em; background-color: #FFF; padding: 2px 5px; border-radius: 5px;`,
  );
}

const enableLogging = true;

// TODO convert this into a loggler singleton with info, warn, error, debug, etc.
export const logger = (logData: any, error?: boolean) => {
  /**
   * Turn off logging in production
   */
  if (!enableLogging) return;
  if (import.meta?.env?.MODE === "production") return;
  if (error) {
    console.error("---- ERROR ----");
  }

  if (logData?.length === 2) {
    console.log(`${logData[0]}:`, logData[1]);
  } else if (typeof logData === "object" && logData?.length > 0) {
    logData
      ?.filter((item) => item)
      ?.forEach((message: any) => {
        console.log(message);
      });
  } else if (logData !== undefined) {
    console.log(logData);
  }

  if (error) {
    console.error("---- ERROR ----");
  }
};
