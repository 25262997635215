import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { Slider, SliderProps, useTheme } from "@mui/material";
import { useContext } from "react";
import { throttle } from "throttle-typescript";
import { AudioPlayerProps } from "./AudioPlayer";

type ChangeCurrentTimeProps = (args: {
  audioElement: HTMLAudioElement;
  duration?: number;
  feedItemId?: string;
}) => SliderProps["onChange"];

export interface PlaybackTimelineProps extends Omit<SliderProps, "size"> {
  className?: string;
  duration: number;
  feedItemId: string;
  position: number;
  size?: AudioPlayerProps["size"];
}

export default function PlaybackTimeline({
  className = "",
  duration = 0,
  feedItemId,
  position,
  size,
  ...rest
}: PlaybackTimelineProps) {
  const { audioElement } = useContext(AudioAppContext);
  const theme = useTheme();

  const changeCurrentTimeForTimeline = throttle((e, v) => {
    if (audioElement) {
      const currentPosition = (duration / 100) * v;
      if (audioElement.fastSeek instanceof Function) {
        audioElement.fastSeek(currentPosition);
      } else {
        audioElement.currentTime = currentPosition;
      }
    }
  }, 250);

  return (
    <Slider
      role="slider"
      className={className}
      onChange={changeCurrentTimeForTimeline}
      size={size === "large" ? "medium" : size ?? "small"}
      value={position}
      sx={{
        color: theme.palette.text.primary,
        ".MuiSlider-rail": { height: "4px", borderRadius: 0 },
        ".MuiSlider-track": {
          height: "4px",
          borderRadius: 0,
        },
      }}
      {...rest}
    />
  );
}
