import { padNumber, secondsToHourMinuteSecond } from "@/utils";

export function toTimeString(time: number) {
	if(!time || time === 0){
		return '00:00'
	}
	const date = new Date();
	const isTimeOverAnHour = time / 3600;
	if (isTimeOverAnHour > 1) {
		const timeObject = secondsToHourMinuteSecond(time);
		return `${timeObject.h}:${padNumber(timeObject.m)}:${padNumber(
			Math.floor(timeObject.s),
		)}`;
	}
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(time);
	return date.toTimeString().slice(3, 8);
}
