import {
  AllowedLanguage,
  SupportedLanguage,
} from "web-client/api/data-contracts";

// This is the list of AWS support langauges (plus 'none' for no translation)
// Do not modify.
// This list needs to stay in sync with the backend and other surfaces.
// TBD on centralizing this list.

export type PreferredLanguage = "none" | AllowedLanguage;
export type AllPreferredLanguage = PreferredLanguage | SupportedLanguage;

const Languages: Record<AllPreferredLanguage, string> = {
  none: "No Translation",
  af: "Afrikaans",
  am: "Amharic",
  ar: "Arabic",
  az: "Azerbaijani",
  bg: "Bulgarian",
  bn: "Bengali",
  bs: "Bosnian",
  ca: "Catalan",
  cs: "Czech",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  el: "Greek",
  en: "English",
  es: "Spanish",
  "es-MX": "Spanish (Mexico)",
  et: "Estonian",
  fa: "Farsi (Persian)",
  "fa-AF": "Dari",
  fi: "Finnish",
  fr: "French",
  "fr-CA": "French (Canada)",
  ga: "Irish",
  gu: "Gujarati",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  hr: "Croatian",
  ht: "Haitian Creole",
  hu: "Hungarian",
  hy: "Armenian",
  id: "Indonesian",
  is: "Icelandic",
  it: "Italian",
  ja: "Japanese",
  ka: "Georgian",
  kk: "Kazakh",
  kn: "Kannada",
  ko: "Korean",
  lt: "Lithuanian",
  lv: "Latvian",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  nl: "Dutch",
  no: "Norwegian (Bokmål)",
  pa: "Punjabi",
  pl: "Polish",
  ps: "Pashto",
  pt: "Portuguese (Brazil)",
  "pt-PT": "Portuguese (Portugal)",
  ro: "Romanian",
  ru: "Russian",
  si: "Sinhala",
  sk: "Slovak",
  sl: "Slovenian",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  th: "Thai",
  tl: "Filipino, Tagalog",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  vi: "Vietnamese",
  zh: "Chinese (Simplified)",
  "zh-TW": "Chinese (Traditional)",
};

export const LANGUAGE_LIST: PreferredLanguage[] = [
  "en",
  "es",
  "es-MX",
  "pt",
  "fr",
  "zh-TW",
  "pl",
];

const PREFERRED_LANGUAGE_VALUES = {
  en: "English (US)",
  es: "Español (ES)",
  "es-MX": "Español (MEX)",
  pt: "Português",
  fr: "Français",
  "zh-TW": "中国传统标签",
  pl: "Polski (PL)",
};

export const LANGUAGE_MAP = LANGUAGE_LIST.map((language) => ({
  text: PREFERRED_LANGUAGE_VALUES[language],
  value: language,
}));
