import SendIcon from "@/components/Icons/Send";
import PlaybackIndicator from "@/components/PlaybackIndicator/Index";
import RadialProgress from "@/components/RadialProgress";
import AudioLevelIndicator from "@/components/Visualization/AudioLevelIndicator";
import useLocationChange from "@/hooks/useLocationChange";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { AppContext } from "@/models/AppStateProvider";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { DataContext } from "@/models/DataProvider";
import { FeedContext } from "@/models/FeedContextProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { VoiceAssistantContext } from "@/models/VoiceAssistantContextProvider";
import { useBootTimesStore } from "@/stores/useBootTimesStore";
import { buildVersion } from "@/utils";
import { AUDIO_FILTERS, getBandpassData } from "@/utils/audio";
import type { AudioNodeInterface } from "@/utils/recording";
import {
  type AudioContextInterface,
  cancelWakeLock,
  clearIndexDB,
  fetchAudioMetaData,
  fetchUploadConfig,
  forceFileDownload,
  getPaddedTime,
  monitorAudioLevels,
  requestWakeLock,
  setupAudioNode,
} from "@/utils/recording";
import ClearIcon from "@mui/icons-material/Clear";
import HourglassTopTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import MicIcon from "@mui/icons-material/Mic";
import {
  Alert,
  type AlertColor,
  Box,
  Chip,
  Snackbar,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import Sessioncore from "@storyboard-fm/sessioncore";
import Bowser from "bowser";
import cuid from "cuid";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTus } from "use-tus";
import TTSForm from "../ModalForms/TTSForm";
import { drawerOpenStyles } from "../SBAppBar";
import "./index.css";

const showSnackBar: boolean = false;
const sessionCorePrefix: string = "sessioncore-";
const maxRecordingLength: number = Number(
  import.meta.env.VITE_SESSION_RECORDING_LENGTH ?? 900,
);
const intervalLength: number = Number(
  import.meta.env.VITE_SESSION_RECORDING_INTERVAL ?? 90,
);

interface SessionCoreInterface {
  id: string;
  ctx: AudioContext;
  source: MediaStreamAudioSourceNode;
  setup: () => void;
  start: () => void;
  stop: () => void;
  wav: () => Blob;
  blob: () => Blob;
}

interface IntervalInterface {
  message: string;
  color: string;
  severity: AlertColor;
}

interface TimeInterface {
  milliseconds: number;
  seconds: number;
  time: string;
}

const initialTimeObject = {
  milliseconds: 0,
  seconds: 0,
  time: "00:00",
};

/**
 * Interval Steps. Automatically set when interval changes
 */
const intervals: IntervalInterface[] = [
  { message: "", color: "#00ff04", severity: "info" },
  {
    message: "Shorter messages are easier to consume",
    color: "#00ff04",
    severity: "info",
  },
  {
    message:
      "Consider recording multiple short messages rather than one long one",
    color: "#84ff00",
    severity: "info",
  },
  { message: "", color: "#c8ff00", severity: "success" },
  { message: "", color: "#fffb00", severity: "success" },
  { message: "", color: "#FFC400FF", severity: "warning" },
  { message: "", color: "#ff9900", severity: "warning" },
  { message: "", color: "#ff7300", severity: "warning" },
  { message: "", color: "#ff4400", severity: "error" },
  {
    message: `The recording limit is ${maxRecordingLength / 60} min`,
    color: "#ff1e00",
    severity: "error",
  },
  { message: "", color: "#ff0073", severity: "error" },
];

const SessionRecorderWrapper = styled("div")({});

export default function SessionRecorderFooter({
  workspaceId,
  feedId,
}: { workspaceId: string; feedId: string }) {
  const { ampli } = React.useContext(TrackingContext);
  const { config, flags, client } = useContext(AppContext);
  const { voiceAssistant, command } = useContext(VoiceAssistantContext);

  const { publishToWorkspaceFeed } = useContext(ActionContext);
  const { preferredLanguage } = useContext(DataContext);
  const { currentFeed } = useContext(CurrentFeedContext);
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const { myAccount } = useContext(MyAccountContext);
  const { playerPlaying, playItem, pauseItem, activeItemId } =
    React.useContext(AudioAppContext);
  const { vListRef, scrollToBottomOfFeed, items } = useContext(FeedContext);

  const forceDownload: boolean = flags.recordingDebug ?? false;
  const debugConsole: boolean = flags.recordingDebug ?? false;

  const theme = useTheme();
  const { isSmUp, leftNavOpen, rightNavOpen } = useContext(UxContext);

  const { upload, setUpload } = useTus();
  const { platform,  browser } = Bowser.parse(
    window.navigator.userAgent,
  );
  const isFirefox = browser?.name?.toLowerCase()?.includes("firefox");
  const isMobile: boolean = platform.type === "mobile";

  /**
   * useRef
   */
  const requestRef = useRef<number | undefined>(undefined);
  const sessionCore = useRef<SessionCoreInterface>({} as SessionCoreInterface);
  const startTime = useRef<Date>(new Date());
  const queuePlayingRef = useRef<boolean>(playerPlaying);

  /**
   * useState
   */
  const [audioLevel, setAudioLevel] = useState<number>(0);
  const [audioNode, setAudioNode] = useState(null as AudioNodeInterface);
  const [color, setColor] = useState<string>("");
  const [counter, setCounter] = useState<number>(0);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<object | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [recording, setRecording] = useState<boolean>(false);
  const [sessionName, setSessionName] = useState<string>(
    `${sessionCorePrefix}${Date.now()}`,
  );
  const [severity, setSeverity] = React.useState<AlertColor>("info");
  const [snackBarOpen, setSnackBarOpen] = React.useState<boolean>(false);
  const [timeObject, setTimeObject] =
    useState<TimeInterface>(initialTimeObject);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [wakeLock, setWakeLock] = useState(null);
  const [contentId, setContentId] = useState<string>(null);
  const radialProgressIndicator = useRef<HTMLButtonElement>();
  const buttonSize = radialProgressIndicator.current?.offsetWidth;
  const radialProgressBarSize = buttonSize + 30;

  /**
   * Button disabled state
   */
  const disabledState = useMemo(() => {
    if (disabled) {
      return true;
    }
    if (error) {
      return false;
    }
    if (processing) {
      return true;
    }
    if (uploadProgress > 0) {
      return true;
    }
    return false;
  }, [processing, uploadProgress, error, disabled]);

  const markUploadAsComplete = () => {
    setProcessing(() => false);
    setContentId(() => null);
    setUploadProgress(() => 0);
    setCounter(() => 0);
    setError(() => null);
    setDisabled(() => false);
    clearIndexDB(sessionName, null).then((data) => data);
  };

  const focusToTTSForm = () => {
    const input = document.getElementById("tts-form-text-input");
    if (input) {
      setTimeout(() => input.focus(), 50);
    }
  };

  /**
   * Handle File Upload
   * @param file
   * @param duration
   */
  const setupUploadData = async (file: Blob, duration: number) => {
    const { stage, tusUrl } = fetchUploadConfig(config);
    const uploadContentId = contentId || cuid();

    const item = await publishToWorkspaceFeed({
      workspaceId,
      feedId,
      contentId: uploadContentId,
      isSilent: currentFeed?.isSilent ? true : false,
    });

    const itemId = item?.id;
    client.createContentEvent({
      contentId: uploadContentId,
      step: "client_uploading",
      status: "started",
      feedId,
      workspaceId: currentWorkspaceId,
    });
    const startTime = Date.now();

    const audioMetaData: AudioContextInterface = fetchAudioMetaData(
      sessionCore.current.ctx,
    );

    const metadata = {
      filename: uploadContentId,
      filetype: file.type,
      id: uploadContentId,
      codec: "wav",
      type: "audio",
      uploadId: uploadContentId,
      surface: "web",
      surfaceContext: window?.navigator?.userAgent,
      surfaceBuild: buildVersion(),
      stage: stage,
      feedItemId: itemId,
      feedId: feedId,
      accountId: myAccount?.id,
      duration: duration ? duration.toString() : "",
      preferredLanguage,
      inputType: "Recording",
      ...audioMetaData,
    };

    console.log({ metadata });

    setUpload(file, {
      storeFingerprintForResuming: true,
      endpoint: tusUrl,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata,
      onChunkComplete: (chunkSize, bytesAccepted, bytesTotal) => {
        console.log(
          "onChunkComplete",
          { chunkSize },
          { bytesAccepted },
          { bytesTotal },
        );
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        if (bytesUploaded > 0) {
          const percentage = Math.floor((bytesUploaded / bytesTotal) * 100);
          console.log({ percentage });
          setUploadProgress(() => percentage);
        }
      },
      onError: async (error) => {
        console.error("There wan an error:", error);
        ampli.uploadFailed({ feedId });
        setUploadProgress(() => 0);
        setDisabled(() => false);
        setError(() => error);
        Sentry.captureException(error);
        client.createContentEvent({
          workspaceId: currentWorkspaceId,
          contentId: uploadContentId,
          step: "client_uploading",
          status: "failed",
          feedId,
          context: `${Date.now() - startTime}ms`,
          error,
        });
      },
      onSuccess: async () => {
        console.log("File Upload Complete");
        client.createContentEvent({
          workspaceId: currentWorkspaceId,
          contentId: uploadContentId,
          step: "client_uploading",
          status: "finished",
          feedId,
          context: `${Date.now() - startTime}ms`,
        });
        markUploadAsComplete();
        ampli.uploadRecord();
      },
      onShouldRetry: (err, retryAttempt, options) => {
        console.log(err, retryAttempt, options);
        Sentry.captureException(err);
        return true;
      },
    });
  };

  /**
   * Process time with animationFrame
   * Builds a time object
   * milliseconds, seconds and time
   */
  const buildTimeObject = () => {
    requestRef.current = requestAnimationFrame(buildTimeObject);
    const now = new Date().getTime();
    const then = startTime.current.getTime();
    const timeFloorAbs = Math.floor(Math.abs(then - now) / 1000);
    setTimeObject(() => ({
      milliseconds: Math.floor(Math.abs(then - now)),
      seconds: timeFloorAbs,
      time: getPaddedTime(timeFloorAbs),
    }));
  };

  /**
   * Start Timer
   */
  const startTimer = (ctx: AudioContext) => {
    startTime.current = new Date();
    cancelAnimationFrame(requestRef.current);
    requestRef.current = requestAnimationFrame(buildTimeObject);
  };

  /**
   * Stop timer
   */
  const stopTimer = () => {
    setTimeObject(() => initialTimeObject);
    cancelAnimationFrame(requestRef.current);
  };

  const [firefoxTimeout, setFirefoxTimeout] = useState<boolean>(false);

  /**
   * Start recording
   * Setup Session Core
   * Setup Audio Node to monitor audio levels
   * Start Timer
   */
  const startRecording = async () => {
    if (isFirefox) {
      setFirefoxTimeout(() => true);
    }

    const localSessionName = cuid();
    setContentId(() => localSessionName);
    setSessionName(() => localSessionName);
    client.createContentEvent({
      workspaceId: currentWorkspaceId,
      contentId: localSessionName,
      step: "client_recording",
      status: "started",
      feedId,
    });
    const session = new Sessioncore(localSessionName);
    const f1 = AUDIO_FILTERS.STANDARD.HIGHPASS;
    const f2 = AUDIO_FILTERS.STANDARD.LOWPASS;
    const { centerFreq, q } = getBandpassData(f1, f2);
    await session.setup(null, { bandpass: [centerFreq, q] });
    const ctx = session.ctx;
    await session.start();
    const localAudioNode = await setupAudioNode();
    if (localAudioNode) {
      setAudioNode(() => localAudioNode);
    } else {
      setAudioNode(() => null);
    }
    const wakeLock = await requestWakeLock();

    /**
     * Conditionally wait for firefox to load sessioncore, since it's not instant
     * 2 seconds seems to be a long enough time to wait for it to load
     */
    if (isFirefox) {
      await new Promise((resolve) => setTimeout(resolve, 2200));
      setFirefoxTimeout(() => false);
    }

    // set the previous playback state before we pause the recording
    queuePlayingRef.current = playerPlaying;
    if (playerPlaying) {
      pauseItem();
    }
    setWakeLock(() => wakeLock);
    setRecording(() => true);
    startTimer(ctx);
    ampli.startRecord({ feedId });
    sessionCore.current = session;
  };

  const compareTimerDurationWithSessionCoreDuration = (
    duration,
    sessionCoreDuration,
  ) => {
    // how long the timer says the audio is
    const loggedDuration: number = Math.ceil(duration);
    // how long session core says the audio is
    const sessionDuration: number = Math.floor(sessionCoreDuration);
    // threshold to check audio difference
    const allowableRange: number = duration / 10 + duration > 10 ? 10 : 0;
    const low: number = loggedDuration - allowableRange;
    const high: number = loggedDuration + allowableRange;
    const durationMisMatch: boolean =
      sessionDuration < low && sessionDuration > high;

    // if the there is an audio mismatch log it
    if (durationMisMatch) {
      ampli.localProcessingError({
        duration: loggedDuration,
        expectedDuration: sessionDuration,
        message: "sessioncore audio duration does not match timer duration",
      });
    }
  };

  /**
   * Stop recording and upload audio file
   */
  const stopRecording = async () => {
    setFirefoxTimeout(() => false);
    const duration = timeObject.milliseconds / 1000;
    // prevent double clicks
    if (disabled) {
      return false;
    }
    client.createContentEvent({
      contentId: contentId,
      step: "client_recording",
      status: "finished",
      feedId,
      workspaceId: currentWorkspaceId,
      context: `${timeObject.milliseconds}ms`,
    });
    setDisabled(() => true);
    setProcessing(() => true);
    stopTimer();
    const startProcessingTime = Date.now();
    client.createContentEvent({
      contentId: contentId,
      step: "client_processing",
      status: "started",
      feedId,
      workspaceId: currentWorkspaceId,
    });
    // wait 1/2 extra second to ensure all recording is complete.
    await new Promise((resolve) => setTimeout(resolve, 500));
    await sessionCore.current.stop();
    setAudioLevel(() => 0);
    setCounter(() => 0);
    setRecording(() => false);
    setSnackBarOpen(() => false);
    focusToTTSForm();

    resumePlayback();

    const file = await sessionCore.current.wav();

    if (!file) {
      ampli.audioProcessingTimeout();
    }

    compareTimerDurationWithSessionCoreDuration(
      duration,
      sessionCore.current.ctx.currentTime,
    );

    // close/stop any tracks for force stop recording
    audioNode.mediaStream.getTracks().forEach((track) => track.stop());
    await cancelWakeLock(wakeLock);

    if (forceDownload) {
      forceFileDownload(file, `${sessionName}.wav`, "audio/x-wav");
    }

    const processingDuration = Date.now() - startProcessingTime;
    if (file) {
      client.createContentEvent({
        contentId: contentId,
        step: "client_processing",
        status: "finished",
        feedId,
        workspaceId: currentWorkspaceId,
        context: `${processingDuration}ms`,
      });
      await setupUploadData(file, duration);
    } else {
      client.createContentEvent({
        contentId: contentId,
        step: "client_processing",
        status: "failed",
        feedId,
        workspaceId: currentWorkspaceId,
        context: `${processingDuration}ms`,
      });
    }
    scrollToBottomOfFeed();
  };

  const resumePlayback = () => {
    // if we were previously playing before the recording started and we have a queue still
    // then continue playback
    if (activeItemId) {
      playItem();
    }
  };

  /**
   * Cancel recording and clear default values
   */
  const cancel = async () => {
    const duration = timeObject.milliseconds / 1000;
    client.createContentEvent({
      contentId: contentId,
      step: "client_recording",
      status: "canceled",
      feedId,
      workspaceId: currentWorkspaceId,
      context: `${timeObject.milliseconds}ms`,
    });
    stopTimer();
    setContentId(() => null);
    setAudioLevel(() => 0);
    setCounter(() => 0);
    setProcessing(() => false);
    // small delay for animations
    setTimeout(() => {
      setRecording(() => false);
      focusToTTSForm();
    }, 300);
    setSnackBarOpen(() => false);
    resumePlayback();
    await sessionCore.current.stop();
    audioNode.mediaStream.getTracks().forEach((track) => track.stop());
    await cancelWakeLock(wakeLock);
  };

  /**
   * Set the current interval / counter level
   * @param seconds
   */
  const setCurrentCounter = (seconds: number) =>
    setCounter(() => Math.floor(seconds / intervalLength));

  /**
   * Create a smooth animation for the progress bar
   * @param milliseconds
   */
  const calculateRadialProgressInMilliSeconds = (milliseconds) => {
    const seconds = milliseconds / 1000;
    const multiplier = counter * 100;
    const radialProgressPercentage = Number(
      (Math.ceil(seconds * 100) / intervalLength - multiplier).toFixed(2),
    );
    if (radialProgressPercentage > 100) {
      setProgress(() => 0);
    } else {
      setProgress(() => radialProgressPercentage);
    }
  };

  /**
   * Auto-Stop recording if seconds is greater or eaual to max recording length
   * @param milliseconds
   */
  const autoStopRecording = async (milliseconds) => {
    const maxMilliseconds = maxRecordingLength * 1000;
    if (milliseconds >= maxMilliseconds && !disabled) {
      await stopRecording();
    }
  };

  /**
   * Close The Snackbar
   */
  const handleClose = () => setSnackBarOpen(false);

  /**
   * Open Snackbar when a message is available
   * @param counter
   */
  const openSnackBar = (counter: number) => {
    const currentInterval = intervals[counter];
    if (!currentInterval) {
      return false;
    }
    const { message, color, severity } = currentInterval;
    if (color) {
      setColor(() => color);
    }

    const hasMessage = message?.length > 1 ? message : false;
    if (hasMessage) {
      setMessage(() => message);
      setSnackBarOpen(() => true);
    }
    if (severity) {
      setSeverity(() => severity);
    }
  };

  /**
   * Close Snackbar
   * @param milliseconds
   */
  const closeSnackBar = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    const currentInterval =
      (seconds + 2) % intervalLength === 1 && seconds + 2 >= intervalLength;

    if (currentInterval && snackBarOpen) {
      setSnackBarOpen(() => false);
    }
  };

  /**
   * Upload file when it's ready
   */
  useEffect(() => {
    if (upload) {
      setProcessing(() => false);
      upload.start();
    }
  }, [upload]);

  /**
   * Open Snack Bar when messages are available
   */
  useEffect(() => {
    openSnackBar(counter);
  }, [counter, openSnackBar]);

  /**
   * Close Snackbar
   * Auto Stop Recording
   * Calculate Radial Progress
   * Set Audio Levels
   */
  useEffect(() => {
    closeSnackBar(timeObject.milliseconds);
    autoStopRecording(timeObject.milliseconds);
    calculateRadialProgressInMilliSeconds(timeObject.milliseconds);
    setAudioLevel(() => monitorAudioLevels(audioNode, isMobile));
  }, [
    timeObject.milliseconds,
    closeSnackBar,
    autoStopRecording,
    calculateRadialProgressInMilliSeconds,
    audioNode,
    isMobile,
  ]);

  /**
   * Update counter based on current time in seconds
   */
  useEffect(() => {
    setCurrentCounter(timeObject.seconds || 0);
  }, [timeObject.seconds, setCurrentCounter]);

  /**
   * On Mount clear index db
   */
  useEffect(() => {
    clearIndexDB(null, sessionCorePrefix);
  }, []);

  const goToPlaybackItem = React.useCallback(() => {
    const currentQueueItemItemId = activeItemId;
    if (currentQueueItemItemId) {
      const queueItemIndex = items.findIndex(
        (item) => item.id === currentQueueItemItemId,
      );
      vListRef?.current?.scrollToIndex(queueItemIndex, {
        align: "start",
        smooth: true,
      });
    }
  }, [items, vListRef, activeItemId]);

  // if a user leaves the current feed and is recording, then cancel their recording
  useLocationChange(() => {
    if (recording) {
      cancel();
    }
  });

  // Current active icon in button
  const buttonIcon = useMemo(() => {
    if (processing) {
      return (
        <HourglassTopTwoToneIcon
          className="processing-request"
          sx={{ fontSize: 45 }}
        />
      );
    }
    if (recording) {
      return <SendIcon />;
    }
    return <MicIcon sx={{ fontSize: 55 }} />;
  }, [recording, processing]);

  // Bottom text on footer
  const bottomMessage = useMemo(() => {
    if (processing) {
      return "Processing Request";
    }
    if (uploadProgress > 0) {
      return "Uploading";
    }
    return `Click to ${recording ? "send" : "record"}`;
  }, [processing, uploadProgress, recording]);

  const showAudioLevels = useMemo(
    () =>
      !!(recording && audioLevel && !disabled && timeObject.milliseconds > 0),
    [recording, audioLevel, disabled, timeObject],
  );

  const _drawerOpenStyles = {
    ...drawerOpenStyles({
      isSmUp,
      leftNavOpen,
      rightNavOpen,
      theme,
    }),
  };

  /**
   * Handle Voice Commands (if enabled)
   */
  useEffect(() => {
    if (voiceAssistant && command) {
      if (command === "start-recording") {
        startRecording();
      } else if (command === "stop-recording") {
        stopRecording();
      } else if (command === "cancel-recording") {
        cancel();
      }
    }
  }, [command, voiceAssistant, cancel, startRecording, stopRecording]);

  const bootTimesStore = useBootTimesStore();
  const sessionRecorderFooterRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (sessionRecorderFooterRef?.current && !bootTimesStore.logsShown) {
      bootTimesStore.addBootTime({
        event: "TTSFooter",
        name: "finish",
      });
    }
  }, [sessionRecorderFooterRef?.current]);

  if (!workspaceId || !feedId) return null;

  return (
    <Box
      className="session-recording-footer-container"
      ref={sessionRecorderFooterRef}
    >
      {uploadProgress > 0 && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            width: "100%",
            px: 2,
            py: 1,
            top: { xs: 100 },
            left: { xs: 0 },
            transform: { xs: "none" },
            zIndex: 1100,
            ..._drawerOpenStyles,
          }}
          open={true}
        >
          <Alert
            icon={false}
            severity="info"
            sx={{
              width: "100%",
              color: theme.palette.primary.main,
              maxWidth: 425,
            }}
            elevation={6}
            variant="filled"
          >
            Sending message...
          </Alert>
        </Snackbar>
      )}
      {recording && showSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            width: "100%",
            px: 2,
            py: 1,
            top: { xs: 100 },
            left: { xs: 0 },
            transform: { xs: "none" },
            ..._drawerOpenStyles,
          }}
          open={snackBarOpen}
        >
          <Alert
            icon={false}
            onClose={handleClose}
            severity={severity}
            sx={{
              width: "100%",
              color: theme.palette.primary.main,
              maxWidth: 1300,
              ".MuiAlert-message": {
                marginLeft: "auto",
              },
            }}
            elevation={6}
            variant="filled"
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <SessionRecorderWrapper
        className="session-recording-footer"
        aria-label={bottomMessage}
        sx={{
          ..._drawerOpenStyles,
        }}
      >
        <div className="session-recording-footer-controls">
          <div
            className={`session-recording-footer-toggle-buttons${
              recording ? " recording" : ""
            }`}
          >
            <Box
              className="session-recording-footer-info"
              role="presentation"
              sx={{
                scale: showAudioLevels ? "100%" : 0,
                opacity: showAudioLevels ? 1 : 0,
                transition: "scale 0.3s ease-in-out, opacity 0.3s ease-in-out",
              }}
            >
              {showAudioLevels && (
                <div>
                  <div className="audio-level-indicator">
                    <AudioLevelIndicator
                      audioLevel={audioLevel}
                      color={theme.palette.primary.main}
                      size={30}
                    />
                  </div>
                  <Typography sx={{ fontWeight: 600, fontSize: "1.25rem" }}>
                    {timeObject.time}
                  </Typography>
                </div>
              )}
            </Box>
            <button
              type="button"
              className="session-recording-footer-cancel"
              onClick={cancel}
              aria-label={Locator.feed.input.recorder.cancel}
              disabled={disabled || !recording}
              tabIndex={recording ? 0 : -1}
            >
              <ClearIcon sx={{ fontSize: 25 }} role="img" />
            </button>
            {!recording && activeItemId && (
              <Chip
                sx={{
                  position: "absolute",
                  top: "-16px",
                  left: "auto",
                  right: "auto",
                  background: theme.palette.brand.primary.light,
                  "&:hover": {
                    background: theme.palette.brand.primary.light,
                  },
                }}
                role="presentation"
                clickable
                onClick={goToPlaybackItem}
                label={
                  <PlaybackIndicator
                    playing={playerPlaying}
                    variant="secondary"
                  />
                }
              />
            )}
            {isFirefox && firefoxTimeout && (
              <Box
                sx={{
                  position: "absolute",
                  background: theme.palette.brand.secondary.light,
                  zIndex: "222",
                  textAlign: "center",
                  fontSize: "11px",
                  borderRadius: "100%",
                  width: "78px",
                  cursor: "not-allowed",
                  height: "78px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>Loading</div>
                <HourglassTopTwoToneIcon sx={{ fontSize: "20px" }} />
              </Box>
            )}
            <button
              ref={radialProgressIndicator}
              type="button"
              id="recordButton"
              className={`session-recording-footer-toggle-button${
                processing ? " processing-request" : ""
              }`}
              disabled={disabledState}
              onClick={recording ? stopRecording : startRecording}
              aria-label={Locator.feed.input.recorder.main}
            >
              {buttonIcon}
            </button>

            {recording && progress > 0 && (
              <Box
                className="radial-progress-indicator"
                aria-label={Locator.feed.input.recorder.progress}
                sx={{
                  position: "absolute",
                  top: "auto",
                  right: "-6.5px",
                  bottom: "auto",
                }}
              >
                <RadialProgress
                  progress={progress}
                  width={radialProgressBarSize}
                  ringColor={theme.palette.secondary.light}
                  progressColor={color}
                  backgroundColor={theme.palette.primary.dark}
                />
              </Box>
            )}
          </div>
          <div className="session-recording-footer-tts-form">
            <TTSForm disableControls={recording} />
          </div>
        </div>
        <Box sx={{ alignSelf: "flex-end", pb: 3 }}>
          {/* <Typography
						sx={{
							fontSize: "0.875rem",
							color: theme.palette.neutral.main,
						}}
					>
						<b>Shift + Enter</b> to start a new line
					</Typography> */}
        </Box>
        {(recording || processing || uploadProgress > 0) && debugConsole && (
          <Box
            sx={{
              left: "20px",
              top: "-400px",
              position: "absolute",
              padding: 1,
              fontSize: "11px",
              background: "#4a3559",
            }}
          >
            <div>Test Console</div>
            <div>
              counter: {counter}/{maxRecordingLength / intervalLength}{" "}
            </div>
            <div>
              max: {maxRecordingLength} seconds or {maxRecordingLength / 60}{" "}
              minutes
            </div>
            <div>seconds: {timeObject?.seconds}</div>
            <div>audioLevel:{Math.floor(audioLevel)}</div>
            <div>uploadProgress:{uploadProgress}</div>
            <div>progress:{progress}</div>
            <div>disabled:{disabled ? "true" : "false"}</div>
            <div>recording:{recording ? "true" : "false"}</div>
          </Box>
        )}
      </SessionRecorderWrapper>
      {recording && <div className="overlay" />}
    </Box>
  );
}
