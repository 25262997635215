import {
  upsertDirectWsInvitation,
  upsertWorkspaceMembership,
  upsertWsAccount,
} from "@/data/pg/updates";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { db } from "@/db/db";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { workspace, workspaceMembership } from "@/db/schema";
import {
  AccountWithHFStatus,
  Workspace,
  WorkspaceMembership,
} from "@/db/types";
import UseTimeouts from "@/hooks/useTimeouts";
import { and, asc, eq } from "drizzle-orm";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../AppStateProvider";
import {
  mapForHasId,
  mapForHasWorkspaceMembershipId,
  workspaceAccountsWithStatusSelect,
} from "../caching";
import {
  justWorkspaceMembershipsQuery,
  myWorkspaceMembershipsQuery,
} from "../commonQueries";
import { MyAccountContext } from "./myAccountProvider";

type WorkspaceState = {
  currentWorkspaceId?: string;
  myCurrentWorkspaceMembership?: WorkspaceMembership;
  workspaceMemberships?: WorkspaceMembership[];
  isWorkspaceAdmin?: () => boolean;
  isWorkspaceLimitedMember?: () => boolean;
  workspaces?: Workspace[];
  accountMap?: Map<string, AccountWithHFStatus>;
  workspaceMembershipMap?: Map<string, AccountWithHFStatus>;
};

export const WorkspaceContext = createContext<WorkspaceState>({});

const LOCATION_ENABLED = true;

const WorkspaceProvider = ({ children }) => {
  const { client } = useContext(AppContext);
  const { myAccountId } = React.useContext(MyAccountContext);
  const { workspaceId } = useParams();
  const { sendDesktopHandsFree } = useFlags();
  const handsFreeAllowed = handsFreeEnabledForWorkspace(workspaceId);

  const { rows: workspaces } = useDrizzleSelect(
    db.select().from(workspace).orderBy(asc(workspace.name)),
  );

  const currentWorkspaceId = workspaceId ?? workspaces[0]?.id;

  const { rows: myCurrentWorkspaceMemberships } = useDrizzleSelect(
    myWorkspaceMembershipsQuery({
      accountId: myAccountId,
      currentWorkspaceId,
    }),
  );

  const myCurrentWorkspaceMembership = myCurrentWorkspaceMemberships[0];

  const { rows: workspaceMemberships } = useDrizzleSelect(
    justWorkspaceMembershipsQuery({ workspaceId }),
  );

  const isWorkspaceAdmin = React.useCallback(() => {
    return myCurrentWorkspaceMembership?.role === "admin";
  }, [myCurrentWorkspaceMembership]);

  const isWorkspaceLimitedMember = React.useCallback(() => {
    return myCurrentWorkspaceMembership?.role === "limitedMember";
  }, [myCurrentWorkspaceMembership]);

  const { rows: accountsWithStatus } = useDrizzleSelect(
    workspaceAccountsWithStatusSelect(workspaceId),
  ) as { rows: AccountWithHFStatus[] };

  const accountMap = useMemo(
    () => mapForHasId(accountsWithStatus),
    [accountsWithStatus],
  );

  const workspaceMembershipMap = useMemo(
    () => mapForHasWorkspaceMembershipId(accountsWithStatus),
    [workspaceMemberships],
  );

  useEffect(() => {
    const f = async () => {
      try {
        if (!workspaceId || !myAccountId) return;
        const membership = await db.query.workspaceMembership.findFirst({
          where: and(
            eq(workspaceMembership.id, workspaceId),
            eq(workspaceMembership.accountId, myAccountId),
            eq(workspaceMembership.status, "active"),
          ),
        });
        if (membership?.role === "limitedMember") return;
        await client.getWorkspaceInvitations(workspaceId).then((resp) => {
          for (const membership of resp?.workspaceMemberships || []) {
            upsertWorkspaceMembership(membership);
          }
          for (const account of resp?.accounts || []) {
            upsertWsAccount(account);
          }
          for (const directInvitation of resp?.directInvitations || []) {
            upsertDirectWsInvitation(directInvitation);
          }
        });
      } catch (e) {
        console.error("Error loading workspace details", e);
      }
    };
    f();
  }, [myAccountId, workspaceId]);

  const localHeartBeat = UseTimeouts(60);
  useEffect(() => {
    if (!handsFreeAllowed || !sendDesktopHandsFree) return;
    if (localHeartBeat?.refresh === false) {
      if (sendDesktopHandsFree && LOCATION_ENABLED) {
        const successCallback = (position: {
          coords: {
            latitude: number;
            longitude: number;
          };
        }) => {
          const timestamp = new Date().toISOString();
          const heartbeat = {
            timestamp,
            appContext: {
              handsFreeEnabled: true,
            },
            deviceContext: {
              location: {
                latitude: position?.coords?.latitude ?? null,
                longitude: position?.coords?.longitude ?? null,
                timestamp,
              },
            },
          };
          client.heartbeat(workspaceId, heartbeat);
        };
        const errorCallback = () => {
          const timestamp = new Date().toISOString();
          const heartBeatNoGeo = {
            timestamp,
            appContext: {},
          };
          client.heartbeat(workspaceId, heartBeatNoGeo);
        };

        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback,
        );
      } else if (sendDesktopHandsFree) {
        const timestamp = new Date().toISOString();
        const heartBeatNoGeo = {
          timestamp,
          appContext: {
            handsFreeEnabled: true,
          },
        };
        client.heartbeat(workspaceId, heartBeatNoGeo);
      }
    }
  }, [
    handsFreeAllowed,
    localHeartBeat?.refresh,
    sendDesktopHandsFree,
    workspaceId,
  ]);

  return (
    <WorkspaceContext.Provider
      value={{
        currentWorkspaceId: workspaceId,
        myCurrentWorkspaceMembership,
        workspaceMemberships,
        workspaces,
        accountMap,
        workspaceMembershipMap,

        isWorkspaceAdmin,
        isWorkspaceLimitedMember,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
