import { UxContext } from "@/models/UxStateProvider";
import {
  Box,
  Drawer,
  DrawerProps,
  Paper,
  useTheme
} from "@mui/material";
import {
  useContext
} from "react";
import "../assets/menus.css";
import MembersDrawerContents from "./MembersDrawerContents";

export interface MembersDrawerProps extends DrawerProps {
  handleClose: () => void;
  url: string | null;
}

export default function MembersDrawer({ sx, handleClose, url }: MembersDrawerProps) {
  const {
    isSmUp,
    rightNavOpen,
  } = useContext(UxContext);
  const theme = useTheme();

  /**
   * @NOTE mui drawer variants
   *  - `persistent` - drawer overlays content, blocks main content
   *      - accessing main content closes drawer
   *  - `temporary` - drawer overlaps but DOES NOT BLOCK main content
   *  - `permanent` - drawer overtakes everything
   */

  const drawerVariant = isSmUp ? "persistent" : "temporary";

  return (
    <Drawer
      variant={drawerVariant}
      sx={sx}
      anchor="right"
      open={rightNavOpen}
      onClose={handleClose}
    >
      <Paper
        sx={{
          background: theme.palette.primary.dark,
          p: 2,
          overflow: "hidden",
          height: "100svh",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
          }}
        >
          {rightNavOpen && (
            <MembersDrawerContents
              handleClose={handleClose}
              url={url}
              sx={sx}
            />
          )}
        </Box>
      </Paper>
    </Drawer>
  );
}
