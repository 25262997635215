import React, { createContext, ReactNode, useContext, useState } from "react";
import { AppContext } from "@/models/AppStateProvider";
import VoiceAssistant from "@/components/VoiceAssistants/Index";

type Props = {
	children: ReactNode | ReactNode[];
};

interface VoiceAssistantState {
	voiceAssistant: boolean;
	transcript: string;
	command: string;
	setTranscript: (transcript: string) => void;
	setCommand: (transcript: string | null) => void;
}

export const VoiceAssistantContext = createContext<VoiceAssistantState>({
	voiceAssistant: false,
	transcript: "",
	command: "",
	setTranscript: () => {},
	setCommand: () => {},
});

const VoiceAssistantContextProvider = ({ children }: Props) => {
	const { flags } = useContext(AppContext);
	const voiceAssistant: boolean = flags.voiceAssistant ?? false;

	const [transcript, setTranscript] = useState<string>("");
	const [command, setCommand] = useState<string | null>(null);

	const VoiceAssistantState: VoiceAssistantState = {
		voiceAssistant,
		transcript,
		command,
		setTranscript,
		setCommand,
	};

	return (
		<VoiceAssistantContext.Provider value={VoiceAssistantState}>
			<VoiceAssistant />
			{children}
		</VoiceAssistantContext.Provider>
	);
};
