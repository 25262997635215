import PodcastItem from "@/components/Search/PodcastItem";
import { DataContext } from "@/models/DataProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Box } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SearchMessageResult } from "../../../web-client/api/data-contracts";
import SearchItemHeader from "./SearchItemHeader";

export default function MessageItem({
  active,
  activeLinkIndex,
  id,
  itemIndex,
  lastItem,
  message,
}: {
  active: boolean;
  activeLinkIndex: number;
  id: string;
  itemIndex: number;
  lastItem: boolean;
  message: SearchMessageResult;
}) {
  const { setGlobalSearchOpen } = useContext(UxContext);
  const { ampli } = useContext(TrackingContext);
  const linkRef = useRef<HTMLAnchorElement>();
  const url = `/workspaces/${message.workspaceId}/feeds/${message.feedId}?feedItemId=${message.feedItemId}`;
  const isPodcast =
    message?.displayTitle?.length > 0 ||
    message?.displayDescription?.length > 0;
  const isPodcastLink = isPodcast && "pod-cast";
  const activeLink = active && "active";
  const lastItemLink = lastItem && "last-item";
  const linkClassName = `search-item ${isPodcastLink} ${activeLink} ${lastItemLink}`;
  const { myActiveFeeds } = useContext(LiveQueryContext);
  const { joinPublicChannel } = useContext(DataContext);
  const navigate = useNavigate();

  const checkPermissions = async (workspaceId: string, feedId: string) => {
    const hasPermissions = !!myActiveFeeds?.find((feed) => feed?.id === feedId);
    if (!hasPermissions) {
      await joinPublicChannel(workspaceId, feedId);
    }
  };

  const clickEvent = async (e?: React.MouseEvent<HTMLAnchorElement>) => {
    if (e) {
      e.preventDefault();
    }
    await checkPermissions(message.workspaceId, message.feedId);
    setGlobalSearchOpen(() => false);
    ampli.searchLinkToMessage({ workspaceId: message.workspaceId });
    navigate(url);
  };

  /**
   * Keyboard enter event handler
   */
  useEffect(() => {
    if (active && activeLinkIndex === itemIndex && linkRef.current) {
      clickEvent().then(() => {
        navigate(url);
      });
    }
  }, [active, activeLinkIndex, active, itemIndex, clickEvent]);

  return (
    <Link
      ref={linkRef}
      id={id}
      to={url}
      className={linkClassName}
      onClick={(e) => clickEvent(e)}
    >
      <Box>
        <SearchItemHeader message={message} />
        {isPodcast && <PodcastItem message={message} />}
        <Box
          dangerouslySetInnerHTML={{
            __html: message?.fullTranscription,
          }}
        />
      </Box>
    </Link>
  );
}
