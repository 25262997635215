import { observe } from "@/ObservedComponent";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { DirectWsInvitation, Feed } from "@/db/types";
import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import accountInfo, { AccountInfo } from "../accountInfo";
import {
  currentFeedPendingInvitesSelect,
  currentFeedQuery,
  myFeedPermissionsQuery,
} from "../commonQueries";
import { WorkspaceContext } from "./workspaceProvider";

type FeedState = {
  currentFeedId?: string;
  currentFeed?: Feed;
  currentFeedAccounts?: Map<string, AccountInfo>;
  currentFeedPendingInvites?: DirectWsInvitation[];
  isCurrentFeedAdmin?: boolean;
  haveWritePermission?: boolean;
};

export const CurrentFeedContext = createContext<FeedState>({});

const CurrentFeedProvider = ({ children }) => {
  let { feedId: currentFeedId } = useParams();
  const { myCurrentWorkspaceMembership } = useContext(WorkspaceContext);

  const feedAccounts = [];

  const { rows: currentFeeds } = useDrizzleSelect(
    currentFeedQuery({
      currentFeedId,
    }),
  );
  const currentFeed = currentFeeds?.[0];

  const currentFeedAccounts = useMemo(
    () => accountInfo(feedAccounts),
    [feedAccounts],
  );

  const { rows: myFeedPermissions } = useDrizzleSelect(
    myFeedPermissionsQuery({
      currentFeedId,
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
  );

  const isCurrentFeedAdmin = useMemo(
    () => myFeedPermissions?.some((p) => p.name === "admin"),
    [myFeedPermissions],
  );
  const haveWritePermission = useMemo(
    () => myFeedPermissions?.some((p) => p.name === "write"),
    [myFeedPermissions],
  );

  const { rows: currentFeedPendingInvites } = useDrizzleSelect(
    currentFeedPendingInvitesSelect({
      currentFeedId,
    }),
  );

  return (
    <CurrentFeedContext.Provider
      value={{
        currentFeedId,
        currentFeed,
        currentFeedAccounts,
        currentFeedPendingInvites,
        isCurrentFeedAdmin,
        haveWritePermission,
      }}
    >
      {children}
    </CurrentFeedContext.Provider>
  );
};

export default observe(CurrentFeedProvider);
