import Feed from "@/components/Feed";
import Loading from "@/components/Loading/Index";
import NoRightsToViewContentModal from "@/components/NoRightsToViewContentModal";
import { db } from "@/db/db";
import { feed, permission, workspaceMembership } from "@/db/schema";
import useLocationChange from "@/hooks/useLocationChange";
import { DataContext } from "@/models/DataProvider";
import FeedContextProvider from "@/models/FeedContextProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "regenerator-runtime/runtime";
import { eq, and, ne, or } from "drizzle-orm";

export default function FeedId() {
  const { currentFeedId, currentFeed } = useContext(CurrentFeedContext);
  const { joinPublicChannel, availableWorkspaceRoles } =
    useContext(DataContext);
  const { myAccountId } = useContext(MyAccountContext);
  const { fetchNextActiveChannel } = useContext(LiveQueryContext);
  const { myCurrentWorkspaceMembership, currentWorkspaceId } =
    useContext(WorkspaceContext);
  const navigate = useNavigate();
  const [invalidFeed, setInvalidFeed] = useState<boolean>(false);
  const [workspaceRedirect, setWorkspaceRedirect] = useState<string>(
    `/workspaces/${currentWorkspaceId}`,
  );

  useEffect(() => {
    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
  }, []);

  // WEB-767: Set if a user is on a page, since removing their permissions does a check and re-joins them to a channel. If the user is on the page and their permissions are removed, they should be redirected to the next active channel.
  const [isUserOnPage, setIsUserOnPage] = useState<boolean>(false);

  useLocationChange(() => {
    setIsUserOnPage(() => false);
    setTimeout(() => {
      setIsUserOnPage(() => true);
    }, 3000);
  });

  const checkFeedPermissions = async ({
    workspaceMembershipId,
    feedId,
    isUserOnPage,
    workspaceId,
  }: {
    workspaceMembershipId: string;
    workspaceId: string;
    feedId: string;
    isUserOnPage: boolean;
  }) => {
    const feedRecord = await db.query.feed.findFirst({
      where: eq(feed.id, feedId),
    });
    if (!feedRecord) {
      return Promise.reject("Invalid Feed");
    }
    const permissions = await db.query.permission.findMany({
      where: and(
        eq(permission.feedId, feedId),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
        eq(permission.enabled, true),
      ),
    });
    const workspaceMember = await db.query.workspaceMembership.findFirst({
      where: and(
        eq(workspaceMembership.id, workspaceMembershipId),
        eq(workspaceMembership.workspaceId, workspaceId),
      ),
    });
    const limitedMember =
      workspaceMember?.role ===
      availableWorkspaceRoles.get("limitedMember")?.role;
    if (permissions?.length === 0) {
      if (isUserOnPage) {
        // if the user is on a feed, but had their permissions revoked, redirect them to the workspace
        return Promise.reject("redirect");
      }
      // if the feed is public and the user is a member of the workspace, join the feed
      if (!limitedMember && !feedRecord?.isPrivate && workspaceMember) {
        return await joinPublicChannel(workspaceId, feedId);
      }
      return Promise.reject("No Feed Permissions");
    }
    return true;
  };

  useEffect(() => {
    const f = async () => {
      if (myCurrentWorkspaceMembership) {
        // console.log("Checking Feed Permissions", {
        //   feedId: currentFeedId,
        //   workspaceId: myCurrentWorkspaceMembership.workspaceId,
        //   workspaceMembershipId: myCurrentWorkspaceMembership.id,
        //   isUserOnPage,
        // });
        checkFeedPermissions({
          feedId: currentFeedId,
          workspaceId: myCurrentWorkspaceMembership.workspaceId,
          workspaceMembershipId: myCurrentWorkspaceMembership.id,
          isUserOnPage,
        })
          .then(() => {
            setInvalidFeed(() => false);
          })
          .catch(async (value) => {
            if (value && value === "redirect") {
              const redirectUrl = await fetchNextActiveChannel();
              return navigate(redirectUrl);
            } else {
              setWorkspaceRedirect(
                `/workspaces/${myCurrentWorkspaceMembership.workspaceId}`,
              );
              setInvalidFeed(() => true);
            }
          });
      } else {
        setWorkspaceRedirect("/workspaces");
        setTimeout(() => {
          setInvalidFeed(() => true);
        }, 500);
      }
    };
    f();
  }, [myCurrentWorkspaceMembership, currentFeedId, myAccountId, isUserOnPage]);

  if (invalidFeed) {
    return <NoRightsToViewContentModal open={true} />;
  }

  return (
    <FeedContextProvider>
      {!currentFeed ? (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Stack>
      ) : (
        <Feed />
      )}
    </FeedContextProvider>
  );
}
