import {
  useLiveIncrementalQuery,
  useLiveQuery,
} from "@electric-sql/pglite-react";
import { SQL } from "drizzle-orm";
import type { PgSelectBase } from "drizzle-orm/pg-core";
import type { PgRelationalQuery } from "drizzle-orm/pg-core/query-builders/query";

const debugQuery = false;

function printDebugQuery(name: string, query) {
  if (debugQuery) {
    console.log(query.toSQL());
  }
}

export function useDrizzleIncSelect<
  TTableName,
  TSelection,
  TSelectMode,
  TNullabilityMap,
  TDynamic,
  TExcludedMethods,
  TResult,
  TSelectedFields,
  T,
>(
  query: Omit<
    PgSelectBase<
      TTableName,
      TSelection,
      TSelectMode,
      TNullabilityMap,
      TDynamic,
      TExcludedMethods,
      TResult,
      TSelectedFields
    >,
    T
  >,
) {
  printDebugQuery("select query", query);
  const results = useLiveIncrementalQuery(
    query.toSQL().sql,
    query.toSQL().params,
    "id",
  );
  const rows = (results?.rows || []) as unknown as Awaited<typeof query>;
  return { rows };
}

export function useDrizzleSelect<
  TTableName,
  TSelection,
  TSelectMode,
  TNullabilityMap,
  TDynamic,
  TExcludedMethods,
  TResult,
  TSelectedFields,
  T,
>(
  query: Omit<
    PgSelectBase<
      TTableName,
      TSelection,
      TSelectMode,
      TNullabilityMap,
      TDynamic,
      TExcludedMethods,
      TResult,
      TSelectedFields
    >,
    T
  >,
  debug?: boolean,
) {
  printDebugQuery("select query", query);
  const q = query.toSQL();
  const qSql = q.sql;
  // const qParams = q.params?.map((p) => (p === undefined ? "none" : p));
  const qParams = q.params;
  const results = useLiveQuery(qSql, qParams);
  if (debug) {
    console.log("My results", { results, qParams, qSql });
  }

  const rows = (results?.rows || []) as unknown as Awaited<typeof query>;
  return { rows };
}

export function useDrizzleRawQuery<T>(query: SQL<unknown>) {
  if (debugQuery) {
    console.log("raw query", query);
  }
  return useLiveQuery(`${query}`) || [];
}

export function useDrizzleIncQuery<T>(query: PgRelationalQuery<T>) {
  printDebugQuery("inc query", query);
  const results = useLiveIncrementalQuery(
    query.toSQL().sql,
    query.toSQL().params,
    "id",
  );
  const rows = (results?.rows || []) as Awaited<T>;
  return { rows };
}

export function useDrizzleQuery<T>(query?: PgRelationalQuery<T>) {
  printDebugQuery("query", query);
  const results = useLiveQuery(query.toSQL().sql, query.toSQL().params);
  const rows = (results?.rows || []) as Awaited<T>;
  return { rows };
}
