import type { SVGProps } from "react";

export default function EmptyInbox({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="74"
      height="68"
      viewBox="0 0 74 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Empty Inbox</title>
      <mask
        id="mask0_2090_1004"
        maskUnits="userSpaceOnUse"
        x="5"
        y="0"
        width="64"
        height="68"
      >
        <rect x="5.5" width="63" height="68" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2090_1004)">
        <path
          d="M11.8334 56.6667C10.2751 56.6667 8.94105 56.1119 7.83133 55.0021C6.72161 53.8924 6.16675 52.5584 6.16675 51V17C6.16675 15.4417 6.72161 14.1077 7.83133 12.998C8.94105 11.8882 10.2751 11.3334 11.8334 11.3334H57.1667C58.7251 11.3334 60.0591 11.8882 61.1688 12.998C62.2785 14.1077 62.8334 15.4417 62.8334 17V51C62.8334 52.5584 62.2785 53.8924 61.1688 55.0021C60.0591 56.1119 58.7251 56.6667 57.1667 56.6667H11.8334ZM34.5001 36.8334L11.8334 22.6667V51H57.1667V22.6667L34.5001 36.8334ZM34.5001 31.1667L57.1667 17H11.8334L34.5001 31.1667ZM11.8334 22.6667V17V51V22.6667Z"
          fill="white"
        />
      </g>
      <rect
        x="48.4306"
        y="2.59734"
        width="23.1389"
        height="23.1389"
        rx="11.5694"
        fill="#FF6229"
      />
      <rect
        x="48.4306"
        y="2.59734"
        width="23.1389"
        height="23.1389"
        rx="11.5694"
        stroke="#26292C"
      />
      <path
        d="M63.5859 13.4062V15.1406C63.5859 16.0729 63.5026 16.8594 63.3359 17.5C63.1693 18.1406 62.9297 18.6562 62.6172 19.0469C62.3047 19.4375 61.9271 19.7214 61.4844 19.8984C61.0469 20.0703 60.5521 20.1562 60 20.1562C59.5625 20.1562 59.1589 20.1016 58.7891 19.9922C58.4193 19.8828 58.0859 19.7083 57.7891 19.4688C57.4974 19.224 57.2474 18.9062 57.0391 18.5156C56.8307 18.125 56.6719 17.651 56.5625 17.0938C56.4531 16.5365 56.3984 15.8854 56.3984 15.1406V13.4062C56.3984 12.474 56.4818 11.6927 56.6484 11.0625C56.8203 10.4323 57.0625 9.92708 57.375 9.54688C57.6875 9.16146 58.0625 8.88542 58.5 8.71875C58.9427 8.55208 59.4375 8.46875 59.9844 8.46875C60.4271 8.46875 60.8333 8.52344 61.2031 8.63281C61.5781 8.73698 61.9115 8.90625 62.2031 9.14062C62.4948 9.36979 62.7422 9.67708 62.9453 10.0625C63.1536 10.4427 63.3125 10.9089 63.4219 11.4609C63.5312 12.013 63.5859 12.6615 63.5859 13.4062ZM62.1328 15.375V13.1641C62.1328 12.6536 62.1016 12.2057 62.0391 11.8203C61.9818 11.4297 61.8958 11.0964 61.7812 10.8203C61.6667 10.5443 61.5208 10.3203 61.3438 10.1484C61.1719 9.97656 60.9714 9.85156 60.7422 9.77344C60.5182 9.6901 60.2656 9.64844 59.9844 9.64844C59.6406 9.64844 59.3359 9.71354 59.0703 9.84375C58.8047 9.96875 58.5807 10.1693 58.3984 10.4453C58.2214 10.7214 58.0859 11.0833 57.9922 11.5312C57.8984 11.9792 57.8516 12.5234 57.8516 13.1641V15.375C57.8516 15.8854 57.8802 16.3359 57.9375 16.7266C58 17.1172 58.0911 17.4557 58.2109 17.7422C58.3307 18.0234 58.4766 18.2552 58.6484 18.4375C58.8203 18.6198 59.0182 18.7552 59.2422 18.8438C59.4714 18.9271 59.724 18.9688 60 18.9688C60.3542 18.9688 60.6641 18.901 60.9297 18.7656C61.1953 18.6302 61.4167 18.4193 61.5938 18.1328C61.776 17.8411 61.9115 17.4688 62 17.0156C62.0885 16.5573 62.1328 16.0104 62.1328 15.375Z"
        fill="white"
      />
    </svg>
  );
}
