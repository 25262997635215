import type React from 'react';
import { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import Locator from "@/locator";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import AlphaSort from '@/components/Icons/AlphaSort';
import RecentSort from "./Icons/RecentSort";

interface FilterProps {
  channelOrder: string;
  changeChannelSortOrder: (value: string) => void;
}

export default function SearchFilter({ changeChannelSortOrder, channelOrder }: FilterProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  type filterType = {
    text: string;
    value: string;
    icon: React.ReactElement;
    active: boolean;
  }

  const filters: filterType[] = [
    {text:'Alphabetically', value:'alpha-asc', icon: <AlphaSort />, active: false},
    {text:'By most recent', value:'driver-activity', icon: <RecentSort />, active: false},
  ].map((filter)=>({
    ...filter,
    active: filter.value === channelOrder
  }))

  return (
    <>
      <Tooltip title="Sort Channels">
        <button
          type="button"
          onClick={handleClick}
          aria-label={Locator.workspaceNav.channels.filter.options}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <SwapVertIcon />
        </button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Box className='search-filter-menu'>
          <Box className='search-filter-title'>Sort Channels</Box>
          {filters.map((filter, index) => (
            <Box key={filter.value} className={`search-filter-button ${filter.active ? 'active' : ''}`}>
              <button
                type="button"
                onClick={() => {
                  changeChannelSortOrder(filter.value)
                  handleClose()
                }}
              >
                <Box className='search-filter-icon'>
                  {filter.icon}
                </Box>
                <Box>
                  {filter.text}
                </Box>
              </button>
            </Box>
          ))}
        </Box>
      </Menu>
    </>
  );
}

