import AvatarWithStatus from "@/components/AvatarWithStatus";
import Tools from "@/components/Icons/Tools";
import WorkspaceUserProfile from "@/components/Workspace/WorkspaceUserProfile";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import UseFirebase from "@/hooks/firebase/useFirebase";
import Locator from "@/locator";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import {
  Box,
  List,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useContext, useMemo, useState } from "react";
import ChannelDial from "../Icons/ChannelDial";
import ChannelDialOutlined from "../Icons/ChannelDialOutlined";
import WorkspaceDrawerHeader from "./WorkspaceDrawerHeader";
import WorkspaceTools from "./WorkspaceTools";
import WorkspaceChannelListContainer from "@/components/WorkspaceChannelList/WorkspaceChannelListContainer";
import { observe } from "@/ObservedComponent";

function WorkspaceList() {
  const { userReadOnlyMode } = useContext(UxContext);
  const { toolsTab, handsFreePlayback } = useFlags();
  const { isWorkspaceLimitedMember, currentWorkspaceId } =
    React.useContext(WorkspaceContext);
  const workspaceId = currentWorkspaceId;
  const { myAccount } = React.useContext(MyAccountContext);
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<string>("channels");
  const {
    ActivateFirebaseBanner,
    BrowserCantGetTokenModal,
    showNotificationsBanner,
  } = UseFirebase();
  const showHandsFreeToggle =
    handsFreePlayback && handsFreeEnabledForWorkspace(workspaceId);
  const limitedMember = isWorkspaceLimitedMember();

  const initialsContainer = (
    <Box>
      <AvatarWithStatus size="sm" accountId={myAccount?.id} disableTooltip />
    </Box>
  );

  const WorkspaceTabs = useMemo(
    () =>
      [
        {
          enabled: true,
          showCta: true,
          text: "Channels",
          key: "channels",
          active: false,
          component: (
            <WorkspaceChannelListContainer
              notificationsBanner={showNotificationsBanner}
            />
          ),
          background: "transparent",
          icons: {
            active: <ChannelDial />,
            inActive: <ChannelDialOutlined />,
          },
        },
        {
          enabled: toolsTab && !limitedMember,
          showCta: true,
          text: "Tools",
          key: "tools",
          active: false,
          component: <WorkspaceTools />,
          background: theme.palette.secondary.dark,
          icons: {
            active: <Tools style={{ fill: theme.palette.primary.main }} />,
            inActive: <Tools style={{ fill: theme.palette.neutral.main }} />,
          },
        },
        {
          enabled: true,
          showCta: true,
          text: "You",
          key: "you",
          active: false,
          component: <WorkspaceUserProfile />,
          background: theme.palette.secondary.dark,
          icons: { active: initialsContainer, inActive: initialsContainer },
        },
      ]
        .map((item) => ({
          ...item,
          active: activeTab === item.key,
          icon:
            activeTab === item.key ? item.icons.active : item.icons.inActive,
        }))
        .filter((tab) => tab.enabled)
        .filter((tab) => !(userReadOnlyMode && tab.key === "tools")),
    [
      userReadOnlyMode,
      activeTab,
      showHandsFreeToggle,
      showNotificationsBanner,
      theme.palette.secondary.dark,
      theme.palette.primary.main,
      theme.palette.neutral.main,
    ],
  );

  const currentActiveTab = useMemo(
    () => WorkspaceTabs.find((tab) => tab.key === activeTab),
    [WorkspaceTabs, activeTab],
  );

  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateRows: "auto minmax(150px, 1fr) auto",
        background: theme.palette.primary.dark,
        gap: 1.5,
        height: "100%",
      }}
    >
      <WorkspaceDrawerHeader />
      <Stack
        sx={{
          gap: 2,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {BrowserCantGetTokenModal()}
        {showNotificationsBanner && ActivateFirebaseBanner()}
        <Box
          sx={{
            height: "100%",
            borderRadius: 4,
            background: currentActiveTab?.background,
          }}
        >
          {currentActiveTab?.component}
        </Box>
      </Stack>
      <List
        sx={{
          display: "flex",
          p: 0,
        }}
      >
        {WorkspaceTabs?.map((tab) => (
          <ListItemButton
            key={tab.key}
            aria-label={`Toggle ${tab.text} Tab`}
            data-testid={Locator.workspaceNav.workspaceTab(tab.key)}
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              color: tab.active
                ? theme.palette.primary.main
                : theme.palette.neutral.main,
              gap: 0.5,
              background: tab.active
                ? theme.palette.secondary.main
                : "transparent",
              width: 90,
              height: 56,
              borderRadius: "8px",
              p: 1,
            }}
            onClick={() => {
              setActiveTab(() => tab.key);
            }}
          >
            {tab.icon}
            <Typography
              sx={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "500",
                lineHeight: "16px",
              }}
            >
              {tab.text}
            </Typography>
          </ListItemButton>
        ))}
      </List>
    </Stack>
  );
}

export default observe(WorkspaceList);
