import { useState, MouseEvent } from 'react';
import Menu from '@mui/material/Menu';

export default function UseContextMenu() {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6
          }
        : null
    );
  };

  // biome-ignore lint/suspicious/noExplicitAny: <any css values are accepted>
  const defaultStyles = (sx: any) => ({
    display: sx?.display ?? 'block',
    width: sx?.width ?? '100%',
    ...sx
  });

  const handleClose = () => {
    setContextMenu(null);
  };

  const anchorPosition =
    contextMenu !== null
      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
      : undefined;

  // biome-ignore lint/suspicious/noExplicitAny: <any css values are accepted>
  const contextMenuItem = (children: any) => (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
    >
      {children}
    </Menu>
  );

  return {
    contextMenu,
    contextMenuItem,
    defaultStyles,
    handleClose,
    handleContextMenu,
    setContextMenu
  };
}
