export const chunkWithMinSize = (arr, chunkSize, minChunkSize = 0) => {
  const remainder = arr.length % chunkSize;
  const isLastChunkTooSmall = remainder < minChunkSize;
  const totalChunks = isLastChunkTooSmall
    ? Math.floor(arr.length / chunkSize)
    : Math.ceil(arr.length / chunkSize);
  return Array.from({ length: totalChunks }, (_, i) => {
    const chunk = arr.slice(i * chunkSize, i * chunkSize + chunkSize);
    if (i === totalChunks - 1 && isLastChunkTooSmall)
      chunk.push(...arr.slice(-remainder));
    return chunk;
  });
};

// polyfill for older browsers that dont support Object.groupBy
const groupBy = (arr, groupByKeyFn) => {
  return arr.reduce((acc, c) => {
    const key = groupByKeyFn(c);
    acc[key] = acc[key] || [];
    acc[key].push(c)
    return acc;
  }, {})
}
