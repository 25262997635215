import {
	gridPageCountSelector,
	GridPagination,
	useGridApiContext,
	useGridSelector,
} from "@mui/x-data-grid";
import { useState } from "react";
import {
	useTheme,
	Select,
	Stack,
	Toolbar,
	MenuItem,
	Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import MuiPagination from "@mui/material/Pagination";
import { TablePaginationProps } from "@mui/material/TablePagination";

type Props = {
	itemsCount: number;
	itemsPerPageOptions?: Array<number>;
	defaultPage?: number;
	onPageChange: Function;
	onRowsPerPageChange: Function;
};

export default function Pagination({
	itemsCount,
	itemsPerPageOptions = [100, 60, 20],
	defaultPage = 1,
	onPageChange,
	onRowsPerPageChange,
}: Props) {
	const [page, setPage] = useState(defaultPage);
	const [itemsPerPage, setItemsPerPage] = useState(
		itemsPerPageOptions[itemsPerPageOptions?.length - 1],
	);
	const theme = useTheme();

	const handlePageChange = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
		onPageChange(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		const perPage = parseInt(event.target.value, 10);
		setItemsPerPage(perPage);
		setPage(1);
		onRowsPerPageChange(perPage);
	};
	return (
		<Toolbar
			sx={{
				borderRadius: "8px",
				padding: "24px 12px",
				height: 88,
				background: theme.palette.neutral.dark,
				justifyContent: "space-between",
				width: "100%",
				maxWidth: { xs: "calc(100% - 20px)", sm: 1280 },
			}}
		>
			<Stack sx={{ flexDirection: "row", gap: 1.5, alignItems: "center" }}>
				<Select
					variant="filled"
					value={itemsPerPage}
					onChange={handleChangeRowsPerPage}
					autoWidth={true}
					IconComponent={KeyboardArrowDown}
					MenuProps={{
						anchorOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						PaperProps: {
							elevation: 0,
							sx: {
								width: 60,
								border: 0,
								background: theme.palette.brand.primary.main,
								boxShadow: "none",
								borderRadius: "4px",
								borderBottomRightRadius: 0,
								borderBottomLeftRadius: 0,
								marginTop: "4px",
								marginLeft: "-0.5px",
							},
						},
						MenuListProps: {
							sx: {
								paddingTop: "8px",
								paddingBottom: "2px",
								"& .MuiMenuItem-root": {
									fontWeight: 600,
									lineHeight: 1,
									padding: "4px 8px",
									"&.Mui-selected": {
										display: "none",
									},
								},
							},
						},
					}}
					sx={{
						select: {
							textAlign: "left",
							textAlignLast: "left",
							padding: "8px",
							fontSize: "1rem",
							"&.MuiInputBase-input": {
								minHeight: 0,
							},
							'&[aria-expanded="true"]': {
								borderTopRightRadius: 0,
								borderTopLeftRadius: 0,
							},
						},
						svg: {
							right: 2,
						},
					}}
				>
					{itemsPerPageOptions.map((option, index) => (
						<MenuItem key={`page-option-${index}`} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
				<Typography component="div" sx={{ fontWeight: 600 }}>
					per page
				</Typography>
			</Stack>
			<MuiPagination
				color="primary"
				count={Math.ceil(itemsCount / itemsPerPage)}
				page={page}
				onChange={handlePageChange}
				boundaryCount={5}
				siblingCount={3}
				shape="rounded"
				sx={{
					".MuiPaginationItem-icon": {
						fontSize: "2.25rem",
					},
					".MuiPagination-ul": {
						".MuiButtonBase-root.MuiPaginationItem-root": {
							fontWeight: 600,
							"&.Mui-selected": {
								color: theme.palette.primary.main,
								background: theme.palette.brand.primary.main,
							},
							// disable Ripple Effect
							"> .MuiTouchRipple-root": {
								display: "none",
							},
						},
					},
				}}
			/>
		</Toolbar>
	);
}

function DataGridPaginationActions({
	page,
	onPageChange,
	className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
	const apiRef = useGridApiContext();
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	return (
		<MuiPagination
			color="primary"
			className={className}
			count={pageCount}
			page={page + 1}
			onChange={(event, newPage) => {
				onPageChange(event as any, newPage - 1);
			}}
			boundaryCount={5}
			shape="rounded"
		/>
	);
}

export function DataGridPagination(props: any) {
	return (
		<GridPagination ActionsComponent={DataGridPaginationActions} {...props} />
	);
}
