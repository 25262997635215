import { db } from "@/db/db";
import { useDrizzleQuery, useDrizzleSelect } from "@/db/drizzleUtils";
import { feed } from "@/db/schema";
import { emptyQuery } from "@/models/commonQueries";
import { Button } from "@mui/material";
import { eq } from "drizzle-orm";
import { get } from "http";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function getFeed(feedId?: string) {
  const query = db.query.feed.findFirst({
    where: eq(feed.id, feedId),
  });
  if (feedId) {
    return query;
  } else {
    return emptyQuery as unknown as typeof query;
  }
}
export default function RedirectNotification() {
  const buildUrlByLocalFeedId = ({
    feedId,
    workspaceId,
    itemId,
  }: { feedId: string; workspaceId: string; itemId: string }): string => {
    return `/workspaces/${workspaceId}/feeds/${feedId}#${itemId}`;
  };
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const feedId = params.get("feedId");
  const itemId = params.get("itemId");
  const { rows: feedRecord } = useDrizzleQuery(getFeed(feedId));

  useEffect(() => {
    if (feedRecord) {
      window.location.href = buildUrlByLocalFeedId({
        feedId: feedRecord.id,
        workspaceId: feedRecord.workspaceId,
        itemId,
      });
    }
  }, [feedId, itemId]);

  return <Button onClick={() => navigate("/workspaces/")}>Home</Button>;
}
