import { SVGProps } from "react";

export default function RecentSort({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 14H22.5L18.5 18L14.5 14H17.5V0H19.5V14ZM7.5 2C3.64 2 0.5 5.13 0.5 9C0.5 12.87 3.63 16 7.5 16C11.36 16 14.5 12.87 14.5 9C14.5 5.13 11.37 2 7.5 2ZM9.69 11.53L6.5 9.69V6H8V8.82L10.44 10.23L9.69 11.53Z"
        fill="currentColor"
      />
    </svg>
  );
}
