import Locator from "@/locator";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { Button, ButtonProps } from "@mui/material";
import { useContext } from "react";

export interface InviteWorkspaceUsersLinkProps extends ButtonProps {
  setCopied?: () => void;
}

export default function InviteWorkspaceUsersLink({
  variant = "contained",
  setCopied,
  sx,
}: InviteWorkspaceUsersLinkProps) {
  const { currentFeed } = useContext(CurrentFeedContext);
  const { ampli } = useContext(TrackingContext);

  const { inviteModalOpen, toggleInviteModal } = useContext(UxContext);

  const handleClick = () => {
    console.warn("** invite-user/click", {
      inviteModalOpen,
    });
    ampli.addWorkspaceUser();
    toggleInviteModal();
  };

  return (
    <Button aria-label={Locator.workspaceNav.members.addMember} onClick={handleClick} variant={variant} sx={sx}>
      Add users
    </Button>
  );
}
