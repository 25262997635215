import awsCronParser from "aws-cron-parser";
import * as chrono from "chrono-node";
import { millisecondsToMinutes } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";

const getFeedDateId = (date: string) => `feed-date-${date}`;

export const parseCronScheduledDate = (cronStr: string, timezone: string) => {
  const cron = awsCronParser.parse(cronStr);
  if (cron) {
    const offset = millisecondsToMinutes(getTimezoneOffset(timezone));
    const parsedDate = chrono.parseDate(
      awsCronParser.getScheduleDescription(cron),
      {
        instant: new Date(),
        timezone: offset,
      },
      { forwardDate: true },
    );
    return parsedDate;
  }
  return null;
};

export const toMilliseconds = (hrs: number, min: number, sec: number) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;
