export type WorkspaceMember = {
  accountId: string;
  membershipId: string;
  name: string;
  avatarColor: string;
  role: string;
  email: string;
  phoneNumber: string;
};

export type MappedWorkspaceMembership = WorkspaceMember & {
  isCurrentFeedMember: boolean;
  isPendingFeedMember: boolean;
  displayName: string;
};

export function mapWorkspaceMemberships(
  workspaceMembers: WorkspaceMember[],
  accountIds: string[],
  pendingInviteIds: string[],
) {
  return workspaceMembers
    ?.map((member) => ({
      ...member,
      isCurrentFeedMember: accountIds?.includes(member?.accountId),
      isPendingFeedMember: pendingInviteIds?.includes(member?.membershipId),
      displayName: member?.name || member?.email || member?.phoneNumber,
    }))
    .sort((a, b) => a?.displayName?.localeCompare(b?.displayName));
}
