import ModalForm from "@/elements/ModalForm";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function InvalidUser({ validRoles }: { validRoles: string[] }) {
  const navigate = useNavigate();
  const { myAccount } = useContext(MyAccountContext);
  const { currentWorkspaceId: workspaceId, myCurrentWorkspaceMembership } =
    useContext(WorkspaceContext);
  const [invalidUser, setInvalidUser] = useState<boolean>(false);

  const workspaceMembership = myCurrentWorkspaceMembership;

  useEffect(() => {
    if (!myAccount || !workspaceMembership?.role) {
      return;
    }
    if (!validRoles.includes(workspaceMembership.role)) {
      setInvalidUser(() => true);
    } else {
      setInvalidUser(() => false);
    }
  }, [workspaceMembership?.role, myAccount?.id, validRoles.join()]);

  const closeModalAndRedirect = () => {
    navigate(`/workspaces/${workspaceId}`);
  };

  if (invalidUser) {
    return (
      <ModalForm open={invalidUser} onClose={() => closeModalAndRedirect()}>
        <div>
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            You do not have rights to view this content
          </Typography>

          <div>
            Please contact your Storyboard administrator for more information
          </div>
        </div>
      </ModalForm>
    );
  }

  return false;
}
