import ModalForm from "@/elements/ModalForm";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function NoRightsToViewContentModal({
  open,
}: { open: boolean }) {
  const navigate = useNavigate();
  const { workspaces, currentWorkspaceId } = useContext(WorkspaceContext);
  const validWorkspace =
    workspaces?.length > 0 && !!workspaces?.find((ws) => ws.id === currentWorkspaceId);

  const navigatePath = validWorkspace
    ? `/workspaces/${currentWorkspaceId}`
    : `/workspaces/${workspaces?.[0]?.id}`;

  const returnToWorkspace = () => {
    navigate(navigatePath);
  };
  return (
    <ModalForm
      id="no-rights-to-view-content-modal"
      open={open}
      allowClose={false}
      sx={{ padding: "40px 60px" }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 700 }}>
          You do not have rights to view this content.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Please contact your system administrator to add you to this channel,
          or return to your workspace.
        </Typography>
        <Button onClick={() => returnToWorkspace()} variant="contained">
          Return To Workspace
        </Button>
      </Box>
    </ModalForm>
  );
}
