import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Stack, Box, Button, useTheme, Typography } from "@mui/material";
import { Attachment } from "@mui/icons-material";
import TextMessageInput from "../TextMessageInput";
import { FullInput } from "@/components/Utils";
import {
  SchedulingContext,
  UploadType,
} from "@/models/SchedulingContextProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UppyContext } from "@/models/UppyContextProvider";
import { VisuallyHiddenInput } from "@/components/Utils";
import AddedFile from "../UploadFiles/AddedFile";
import Locator from "@/locator";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";

export type FleetMessageContentSelectProps = { cancelHandler?: Function };

export default function FleetMessageContentSelect({
  cancelHandler,
}: FleetMessageContentSelectProps) {
  const { scheduledText, setUploadType, setScheduledText, goToNextStep } =
    useContext(SchedulingContext);
  const { ampli } = useContext(TrackingContext);
  const { uppyClient, addedFile } = useContext(UppyContext);
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);

  const textCharLimit = 5000;
  const disableContinue =
    (!addedFile && !scheduledText) || scheduledText?.length > textCharLimit;
  const theme = useTheme();

  const handleTextInput = (text: string) => {
    setScheduledText(text);
    setUploadType(UploadType.TTS);
  };

  const handleContinue = () => {
    ampli.createFleetMessage({ workspaceId });
    goToNextStep();
  };

  const handleCancel = (e) => {
    cancelHandler(e);
  };

  const handleFileSelection = (e) => {
    try {
      if (e.target?.files?.[0]) {
        uppyClient?.addFile(e.target?.files?.[0]);
        setScheduledText("");
        setUploadType(UploadType.File);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "flex-start",
      }}
    >
      <Stack sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ position: "relative" }}>
          <TextMessageInput
            label={
              <Typography
                sx={{
                  fontWeight: 700,
                  color: addedFile
                    ? theme.palette.neutral.main
                    : theme.palette.primary.main,
                }}
              >
                Message
              </Typography>
            }
            rows={12}
            textInputValue={scheduledText}
            textInputHandler={handleTextInput}
            showHint={false}
            disabled={!addedFile === null}
            aria-label={Locator.feed.input.fleetMessage.modal.tts}
          />
          {addedFile ? (
            <Box
              sx={{
                position: "absolute",
                top: 28,
                left: 0,
                background: theme.palette.secondary.main,
                height: "100%",
                border: `1.5px solid ${theme.palette.secondary.light}`,
                maxHeight: 234,
                width: "100%",
                borderRadius: "8px",
                padding: "12px, 10px, 2px, 7px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: theme.palette.neutral.main,
                  position: "relative",
                  top: 10,
                  left: 8,
                }}
              >
                A fleet message cannot contain text when there is a file
                attached.
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Stack>

      {/* WEB-597 Temporarily disabling the ability to add files to fleet messages */}
      {/* {addedFile ? (
        <AddedFile file={addedFile} />
      ) : (
        <Button
          component="label"
          role={undefined}
          variant="text"
          tabIndex={-1}
          startIcon={<Attachment role="presentation" />}
          sx={{
            maxWidth: "max-content",
            background: theme.palette.secondary.main,
            padding: "12px 16px",
            borderRadius: "8px",
            color: "#D9D9D9",
            height: 48,
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          <Typography sx={{ textTransform: "none" }}>Attach a file</Typography>
          <VisuallyHiddenInput type="file" onChange={handleFileSelection} />
        </Button>
      )} */}

      <Stack
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          gap: 2,
          pt: 3,
        }}
      >
        <Button
          variant="outlined"
          sx={{ order: { xs: 1, sm: 0 } }}
          onClick={handleCancel}
          aria-label={Locator.feed.input.fleetMessage.modal.cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ order: { xs: 0, sm: 1 } }}
          disabled={disableContinue}
          onClick={handleContinue}
          aria-label={Locator.feed.input.fleetMessage.modal.continue}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
}
