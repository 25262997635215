import { db } from "@/db/db";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { workspace } from "@/db/schema";
import { DataContext } from "@/models/DataProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import {
  findWorkspaceQuery,
  myActiveFeedsQuery,
  myWorkspaceMembershipsQuery,
} from "@/models/commonQueries";
import { Divider } from "@mui/material";
import React, { useContext, useEffect } from "react";

export function PgTest() {
  const { bootstrapComplete } = React.useContext(DataContext);
  const { currentFeed } = React.useContext(CurrentFeedContext);
  const [currentWorkspaceId, setCurrentWorkspaceId] = React.useState<string>();
  // const { myActiveFeeds } = useContext(LiveQueryContext);

  const { myAccount } = useContext(MyAccountContext);

  const { rows: memberships } = useDrizzleSelect(
    myWorkspaceMembershipsQuery({
      accountId: myAccount?.id,
      currentWorkspaceId,
    }),
  );

  const myCurrentWorkspaceMembership = memberships?.[0];

  const { rows: myActiveFeeds } = useDrizzleSelect(
    myActiveFeedsQuery({
      workspaceId: currentWorkspaceId,
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
    true,
  );

  const { rows: workspaces } = useDrizzleSelect(
    findWorkspaceQuery({
      workspaceId: currentWorkspaceId,
    }),
  );

  const currentWorkspace = workspaces[0];

  const [bootstrapStatus, setBootstrapStatus] = React.useState("loading");

  useEffect(() => {
    if (bootstrapComplete) {
      setBootstrapStatus("complete");
      db.select()
        .from(workspace)
        .execute()
        .then((workspaces) => {
          const workspace =
            workspaces.find((w) => w.name.match(/dev/i)) || workspaces[0];
          setCurrentWorkspaceId(workspace.id);
        });
    }
  }, [bootstrapComplete]);

  const feedsList = myActiveFeeds?.map((feed) => <li>{feed.title}</li>);

  console.log({ myActiveFeeds });

  return (
    <>
      <h1>{bootstrapStatus}</h1>
      <h2>{currentWorkspace?.name}</h2>
      <div>{currentFeed?.title || "No feed yet"}</div>
      <Divider />
      <ul>{feedsList}</ul>
    </>
  );
}
