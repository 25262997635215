import Flag from "@/components/Icons/Flag";
import Locator from "@/locator";
import { AppContext } from "@/models/AppStateProvider";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { DataContext } from "@/models/DataProvider";
import {
	LANGUAGE_LIST,
	LANGUAGE_MAP,
	PreferredLanguage,
} from "@/models/languages";
import { TrackingContext } from "@/models/TrackingStateProvider";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import * as React from "react";
import { useContext, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

export default function LanguageDropdown() {
	const { ampli } = useContext(TrackingContext);
	const { preferredLanguage, setPreferredLanguage } =
		React.useContext(DataContext);
	const [menuStatus, setMenuStatus] = useState<boolean>(false);
	const wrapperRef = React.useRef(null);

	const toggleMenuStatus = () => {
		setMenuStatus((value) => !value);
	};
	const changeLanguage = (value) => {
		// pause and reset the queue, require user to restart playback
		const translationLanguage =
			LANGUAGE_MAP.find((lang) => lang.value === value)?.text ?? "none";
		ampli.updateUserLanguage({ translationLanguage });
		setPreferredLanguage(value as PreferredLanguage);
		toggleMenuStatus();
	};

	const handleMenuClose = (event) => {
		if (event?.key === "Escape") {
			setMenuStatus(false);
		}
	};

	useOnClickOutside(wrapperRef, () => setMenuStatus(false));

	const languageButtonMap = LANGUAGE_MAP.map((language) => (
		<Button
			key={language.value}
			aria-label={Locator.mainNav.languages.option(language.value)}
			sx={{
				justifyContent: "left",
				display: "flex",
				textTransform: "none",
				alignItems: "center",
			}}
			onClick={() => {
				changeLanguage(language.value);
			}}
		>
			<Box
				sx={{
					flex: "0 1 auto",
					display: "flex",
					mr: 1,
				}}
			>
				<Flag width={24} locale={language.value} title={language.text} />
			</Box>
			<Box
				sx={{
					flex: "1 1 auto",
					textAlign: "left",
					fontSize: "14px",
					fontWeight: language.value === preferredLanguage ? "500" : "400",
					color: language.value === preferredLanguage ? "#fff" : "#C0C0C2",
				}}
			>
				{language.text}
			</Box>
			<Box sx={{ width: "20px", display: "flex" }}>
				{language.value === preferredLanguage ? <CheckIcon /> : " "}
			</Box>
		</Button>
	));

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "end",
				flex: "0 1 auto",
				position: "relative",
			}}
			ref={wrapperRef}
			onKeyDown={handleMenuClose}

		>
			<Box>
				<IconButton
					onClick={toggleMenuStatus}
					aria-label={Locator.mainNav.languages.dropdown}
					sx={{
						background: "transparent",
						borderRadius: "1000px",
					}}
				>
					<Flag width={24} locale={preferredLanguage} />
				</IconButton>
			</Box>
			<Fade in={menuStatus}>
				<Box
					sx={{
						position: "absolute",
						right: 0,
						top: 50,
						width: "250px",
						background: "#26292C",
						border: "solid 2px #606266",
						borderRadius: "0.5rem",
						p: 1,
					}}
				>
					{languageButtonMap}
					<Typography
						sx={{
							px: 1,
							mt: 1,
							fontSize: "11px",
							color: "#999",
						}}
					>
						All message transcriptions will display in the chosen language.{" "}
						<Link
							target="_blank"
							sx={{ color: "#999" }}
							href="https://storyboard.com/transcription-translation-policy"
						>
							Language Policy
						</Link>
					</Typography>
				</Box>
			</Fade>
		</Box>
	);
}
