import UseTimeouts from "@/hooks/useTimeouts";
import { AppContext } from "@/models/AppStateProvider";
import { Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";

export default function MaintenanceMode() {
  const theme = useTheme();
  const { client, flags } = useContext(AppContext);
  const { refresh } = UseTimeouts(60);
  const [disabled, setDisabled] = useState<boolean>(false);

  const checkMaintenanceMode = async () => {
    setDisabled(() => true);
    try {
      await client.getUserInfo();
      window.location.href = "/";
      return true;
    } catch (e) {
      setTimeout(() => {
        setDisabled(() => false);
      }, 5000);
      return false;
    }
  };

  useEffect(() => {
    if (refresh) {
      checkMaintenanceMode();
    }
  }, [refresh]);

  const background =
    // @ts-ignore
    theme?.components?.MuiCssBaseline?.styleOverrides?.body?.background ??
    "#222";

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background,
      }}
    >
      <Box
        sx={{
          padding: 3,
          borderRadius: 2,
          background: theme.palette.secondary.dark,
          textAlign: "center",
        }}
      >
        <Box sx={{ fontSize: "1.5rem", mb: 1, fontWeight: "bold" }}>
          System Maintenance
        </Box>
        <Box>We are currently performing system maintenance.</Box>
        <Box sx={{ mb: 2 }}>Please check back in a few minutes.</Box>
        <Button
          variant="contained"
          disabled={disabled}
          onClick={() => checkMaintenanceMode()}
        >
          {disabled ? "Checking Status..." : "Try Again"}
        </Button>
      </Box>
    </Box>
  );
}
