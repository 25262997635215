import { UxContext } from '@/models/UxStateProvider';
import { MAIN_DRAWER_WIDTH, MEMBERS_DRAWER_WIDTH } from '@/utils';
import { styled } from '@mui/material';
import React from 'react';

const MainContent = styled('main', {
  shouldForwardProp: (prop) => prop !== 'leftOpen' && prop !== 'rightOpen'
})<{
  leftOpen?: boolean;
  rightOpen?: boolean;
}>(({ theme, leftOpen, rightOpen }) => {
  const { isSmUp } = React.useContext(UxContext);
  let openDrawerOffset = 0;
  if (leftOpen) {
    openDrawerOffset += MAIN_DRAWER_WIDTH;
  }
  if (rightOpen) {
    openDrawerOffset += MEMBERS_DRAWER_WIDTH;
  }
  return {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: isSmUp ? `-${MAIN_DRAWER_WIDTH}px` : 0,
    marginRight: isSmUp ? `-${MEMBERS_DRAWER_WIDTH}px` : 0,
    ...(leftOpen && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    }),
    ...(rightOpen && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    }),
    width: isSmUp ? `calc(100% - ${openDrawerOffset}px)` : '100%'
  };
});

export default MainContent;
