import { AudioAppContext } from "@/models/AudioAppContextProvider";
import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useContext } from "react";
import { AudioPlayerProps } from "./AudioPlayer";
import { containerStyle } from "./AudioPlayer/styles";
import { AudioControls } from "./AudioPlayer/timeline";

interface PlayPauseButtonProps
  extends Pick<AudioPlayerProps, "playPauseIconButtonProps"> {
  /**
   * @NOTE `audioElement` + `controls` are now optional as
   *       they come from `AudioAppContext`
   */
  audioElement?: HTMLAudioElement;
  controls?: AudioControls;
  disabled?: boolean;
  id: string;
  playing: boolean;
}

export default function PlayPauseButton({
  disabled = false,
  playing,
  id,
  playPauseIconButtonProps = {},
}: PlayPauseButtonProps) {
  const { pauseItem, playItem, activeItemId, setActiveItemId } =
    useContext(AudioAppContext);

  const handlePlay = () => {
    if (playing) {
      pauseItem();
    } else {
      if (activeItemId === id) {
        playItem();
      } else {
        setActiveItemId(id);
      }
    }
  };

  const { sx: iconButtonSx, ...restIconButtonProps } = playPauseIconButtonProps;
  const mergedSx = { ...containerStyle.playButton, ...iconButtonSx };

  const ariaLabel = playing
    ? "Currently playing, click to pause"
    : "Click to play";

  return (
    <IconButton
      disabled={disabled}
      color="primary"
      onClick={handlePlay}
      sx={mergedSx}
      aria-label={ariaLabel}
      {...restIconButtonProps}
    >
      {playing ? <PauseIcon role="img" /> : <PlayArrowIcon role="img" />}
    </IconButton>
  );
}
