import React from "react";
import { AppContext } from "./AppStateProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "./DataProvider";
import { MyAccountContext } from "./StateProviders/myAccountProvider";

type RouteGuardProps = {
  predicate: () => boolean;
  allowedPaths?: string[];
  redirectPath: string;
};

type Props = {
  children: React.ReactNode | React.ReactNode[];
};
export default function RouteGuard({ children }: Props) {
  const { bootstrapComplete } = React.useContext(DataContext);
  const { setRedirectPath } = React.useContext(AppContext);
  const { myAccount } = React.useContext(MyAccountContext);
  const navigate = useNavigate();
  const location = useLocation();

  const guardRoute = React.useCallback(
    ({ predicate, allowedPaths, redirectPath }: RouteGuardProps) => {
      const allAllowedPaths = [...(allowedPaths || []), redirectPath];
      if (allAllowedPaths?.includes(location.pathname)) return;
      if (!predicate()) return;
      if (location.pathname === redirectPath) return;
      setRedirectPath(location.pathname);
      navigate(redirectPath);
    },
    [navigate, setRedirectPath],
  );

  React.useEffect(() => {
    guardRoute({
      predicate: () => {
        if (!bootstrapComplete) return false;
        if (!myAccount) return false;
        const name = myAccount.name;
        // removing email as required for now since we allow phone numbers but cant verify them
        // const email = myAccount?.email;
        // const requiredInfo = (!name || name === "") && (!email || email === "");
        const requiredInfo = !name || name === "";
        return !!requiredInfo;
      },
      redirectPath: "/onboarding",
    });
  }, [location.pathname, myAccount, guardRoute, bootstrapComplete]);

  return <>{children}</>;
}
