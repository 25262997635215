import { SVGProps } from "react";

export default function AlphaSort({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8212 14.4404H17.8212L13.8212 18.4404L9.82117 14.4404H12.8212V0.44043H14.8212M6.82117 10.4404V12.4404L3.49117 16.4404H6.82117V18.4404H0.821167V16.4404L4.15117 12.4404H0.821167V10.4404M4.82117 0.44043H2.82117C1.72117 0.44043 0.821167 1.34043 0.821167 2.44043V8.44043H2.82117V6.44043H4.82117V8.44043H6.82117V2.44043C6.82117 1.34043 5.93117 0.44043 4.82117 0.44043ZM4.82117 4.44043H2.82117V2.44043H4.82117V4.44043Z"
        fill="currentColor"
      />
    </svg>
  );
}
