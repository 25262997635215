import { db } from "@/db/db";
import { useDrizzleQuery, useDrizzleSelect } from "@/db/drizzleUtils";
import * as schema from "@/db/schema";
import { eq } from "drizzle-orm";
import React, { createContext, useContext, useEffect } from "react";
import { TelemetryContext } from "../TelemetryProvider";
import { account } from "@/db/schema";

type MyAccountState = {
  myAccount?: typeof schema.account.$inferSelect;
  myAccountId?: string;
};

export const MyAccountContext = createContext<MyAccountState>({});

const MyAccountProvider = ({ children }) => {
  const [myAccountId, setMyAccountId] = React.useState<string | undefined>(
    undefined,
  );
  const { setAttribute } = useContext(TelemetryContext);

  const { rows: myAccounts } = useDrizzleSelect(
    db.select().from(account).where(eq(account.mine, true)).limit(1),
  );

  const myAccount = myAccounts?.[0];

  useEffect(() => {
    if (myAccount) {
      setAttribute("accountId", myAccount.id);
      setMyAccountId(myAccount.id);
    }
  }, [myAccount]);

  return (
    <MyAccountContext.Provider value={{ myAccountId, myAccount }}>
      {children}
    </MyAccountContext.Provider>
  );
};

export default MyAccountProvider;
